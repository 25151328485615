import React, { Component } from 'react'
import { Button, Form, Input, Table, } from 'reactstrap';
import Select from 'react-select'
import SecureStorage from 'secure-web-storage';

const optionsEnv = [
    { value: 'prod', label: 'Production' },
    { value: 'stg', label: 'Staging' },
    { value: 'agitha', label: 'Agitha' }
]
const optionsTeam = [
    { value: 'devops', label: 'Devops' },
    { value: 'revmngmt', label: 'Revenue Management' },
    { value: 'searchseo', label: 'Search & SEO' },
    { value: 'bookinq', label: 'Bookings & Inquiries' },
    { value: 'cexp', label: 'Consumer Experience' },
    { value: 'fedemand', label: 'FE-Demand' },
    { value: 'fesupply', label: 'FE-Supply' },
    { value: 'csupply', label: 'Core Supply' },
    { value: 'connchnl', label: 'Connectivity & Channeling' },
    { value: 'pl', label: 'Platform' },
    { value: 'datatm', label: 'Data Team' },
    { value: 'techsupp', label: 'Tech Support' },
    { value: 'itsupp', label: 'IT Support' }
]
export default class CreateGCSBucket extends Component {

    constructor(props) {
        super(props);
        this.state = {
            team: '',
            service: '',
            env: '',
            envName: '',
            invalid: true,
            checkTeam: false,
            EnvReset: -1,
            TeamReset: -1,
            showEnv: false,
            showService: false,
            showTeam: false,
            secStorage: '',
        }
        this.handleChangeTeam = this.handleChangeTeam.bind(this)
        this.handleChangeService = this.handleChangeService.bind(this)
        this.handleChangeEnv = this.handleChangeEnv.bind(this)
    }

    componentDidMount() {
        var CryptoJS = require("crypto-js");
        var SECRET_KEY = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY;
        var secureStorage = new SecureStorage(localStorage, {
            hash: function hash(key) {
                key = CryptoJS.SHA256(key, SECRET_KEY);
                return key.toString();
            },
            encrypt: function encrypt(data) {
                data = CryptoJS.AES.encrypt(data, SECRET_KEY);
                data = data.toString();
                return data;
            },
            decrypt: function decrypt(data) {
                data = CryptoJS.AES.decrypt(data, SECRET_KEY);
                data = data.toString(CryptoJS.enc.Utf8);
                return data;
            }
        });
        this.setState({ secStorage: secureStorage.getItem('UserInfo') })

    }

    handleChangeTeam = (e) => {
        this.setState({ showEnv: true })
        //this.setState({ showService: true })
        this.setState({ team: e.value })
        if (this.state.service.length > 0 & e.value.length > 0 & this.state.env.length > 0) this.setState({ invalid: false }); else { this.setState({ invalid: true }); this.setState({ showTeam: true }) }
        this.setState({ TeamReset: e })
    }

    handleChangeService = (e) => {
        this.setState({ showTeam: true })
        this.setState({ showEnv: true })
        if (this.state.team.length > 0 & e.target.value.length > 0 & this.state.env.length > 0) this.setState({ invalid: false }); else { this.setState({ invalid: true }); this.setState({ showService: true }) }
        this.setState({ service: e.target.value })
    }

    handleChangeEnv = (e) => {
        this.setState({ showTeam: true })
        if (this.state.service.length > 0 & e.value.length > 0 & this.state.team.length > 0) this.setState({ invalid: false }); else this.setState({ invalid: true });
        this.setState({ env: e.value })
        this.setState({ envName: e.label })
        this.setState({ EnvReset: e })
    }

    submitHandler = (e) => {
        console.log(this.state)
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var today = new Date()
        var axios = require('axios');
        var FormData = require('form-data');
        var data = new FormData();
        let mon = today.getMonth() + 1
        data.append('username', this.state.secStorage.email);
        data.append('environment', this.state.env);
        data.append('team', this.state.team);
        data.append('service', this.state.service);
        data.append('approvedBy', 'Pending');
        data.append('status', 'Pending');
        data.append('timestamp', today.getDate() + "-" + mon + "-" + today.getFullYear() + ":" + today.getHours() + ":" + today.getMinutes());
        var config = {
            method: 'post',
            url: baseUrl + '/gcsbucketcreate',
            headers: {
                'Authorization': 'Basic ' + base64,
                'content-type': 'multipart/form-data'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });
        this.setState({ EnvReset: -1 })
        this.setState({ TeamReset: -1 })
        document.getElementById("form2").reset();
        this.setState({ invalid: true });
        this.setState({ showEnv: false })
    }

    render() {
        return (
            <div style={{ marginLeft: 20 }}>
                <Form id="form2">
                    <Table style={{ fontSize: 16, width: "30%", marginLeft: 400, marginTop: "18%", }}>
                        <tbody >
                            <tr>
                                <td>
                                    <Select value={this.state.EnvReset} placeholder="Select Environment" id="category" options={optionsEnv} onChange={(e) => this.handleChangeEnv(e)} />
                                    {this.state.envName.length <= 0 && this.state.showEnv &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>Select Environment</span>}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Select value={this.state.TeamReset} placeholder="Select Team" id="category" options={optionsTeam} onChange={(e) => this.handleChangeTeam(e)} />
                                    {this.state.team.length <= 0 && this.state.showTeam &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>Select Team</span>}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Input style={{ fontSize: 16 }} type="text" placeholder="Enter service" onChange={(e) => this.handleChangeService(e)} />
                                    {this.state.service.length <= 0 && this.state.showService &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>Service name cannot be empty</span>}
                                </td>
                            </tr>
                            <tr>
                                <td><Button disabled={this.state.invalid} style={{ fontSize: 16, marginLeft: 130, backgroundColor: "#33382e", color: "white" }} onClick={this.submitHandler} >Submit</Button></td>
                            </tr>
                        </tbody>
                    </Table>

                </Form>
            </div>
        )
    }
}