import '../index.css';
import React, { Component, } from 'react';
import { Button, Input, Table } from 'reactstrap';
import Select from 'react-select'

const option = [
    { value: 'Devops', label: 'Devops' },
    { value: 'Revenue Management', label: 'Revenue Management' },
    { value: 'Search & SEO', label: 'Search & SEO' },
    { value: 'Bookings & Inquiries', label: 'Bookings & Inquiries' },
    { value: 'Consumer Experience', label: 'Consumer Experience' },
    { value: 'FE-Demand', label: 'FE-Demand' },
    { value: 'FE-Supply', label: 'FE-Supply' },
    { value: 'Core Supply', label: 'Core Supply' },
    { value: 'Connectivity & Channeling', label: 'Connectivity & Channeling' },
    { value: 'Platform', label: 'Platform' },
    { value: 'Data Team', label: 'Data Team' },
    { value: 'Tech Support', label: 'Tech Support' },
    { value: 'IT Support', label: 'IT Support' }
]

class UpdateUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            val: "",
            page: '',
            noOfEntries: '',
            team: '',
        }
        this.submitHandler = this.submitHandler.bind(this)
        this.changeHandler = this.changeHandler.bind(this)
        this.optionValue = this.optionValue.bind(this)
    }

    componentWillReceiveProps(props) {
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var currentThis = this;
        this.setState({ list: [] })
        var axios = require('axios');
        var config = {
            method: 'get',
            url: baseUrl + '/users?page=' + props.page + '&noOfEntries=' + props.numberOfEntries,
            headers: {
                'Authorization': 'Basic ' + base64,
            }
        };
        axios(config)
            .then(function (response) {
                currentThis.optionValue(response.data.content)
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });
    }

    componentDidMount() {
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var currentThis = this;
        var axios = require('axios');
        var config = {
            method: 'get',
            url: baseUrl + '/users?page=' + this.props.page + '&noOfEntries=' + this.props.numberOfEntries,
            headers: {
                'Authorization': 'Basic ' + base64,
            }
        };
        axios(config)
            .then(function (response) {
                currentThis.optionValue(response.data.content)
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });
    }


    optionValue(list) {
        this.setState({ list: list })
    }

    changeHandler = (e) => {
        this.setState({ team: e.value })
    }

    submitHandler(email, name, role, adminStatus, team, timestamp, imageUrl, gitlabId) {
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var FormData = require('form-data');
        var axios = require('axios');
        var data = new FormData();
        data.append('email', JSON.parse(JSON.stringify(email)).email);
        data.append('name', JSON.parse(JSON.stringify(name)).name);
        data.append('gitlabId', JSON.parse(JSON.stringify(gitlabId)).gitlabId);
        data.append('role', JSON.parse(JSON.stringify(role)).role);
        data.append('team', this.state.team);
        data.append('adminStatus', JSON.parse(JSON.stringify(adminStatus)).adminStatus);
        data.append('profilePicUrl', JSON.parse(JSON.stringify(imageUrl)).profilePicUrl);
        data.append('timestamp', JSON.parse(JSON.stringify(timestamp)).timestamp);
        var config = {
            method: 'put',
            url: baseUrl + '/users',
            headers: {
                'Authorization': 'Basic ' + base64,
                'content-type': 'multipart/form-data'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    renderTableData() {
        var i = 0;
        return this.state.list.map((request) => {
            const { email, name, role, team, adminStatus, timestamp, profilePicUrl, gitlabId } = request
            var bgcolor;
            if (i % 2 === 0) bgcolor = "tan"; else bgcolor = "papayawhip";
            i = i + 1;
            return (
                <tr key={email} style={{ fontSize: 14, backgroundColor: bgcolor, }}>
                    <td>{email}</td>
                    <td>{name}</td>
                    <td>{gitlabId}</td>
                    <td>{role}</td>
                    <td>{timestamp}</td>
                    <td ><Select id="teamsId" options={option} defaultInputValue={team} defaultValue={team} onChange={(e) => this.changeHandler(e)} /></td>
                    <td><Button style={{ backgroundColor: "#33382e", color: "white", width: 100 }} onClick={() => this.submitHandler({ email }, { name }, { role }, { adminStatus }, { team }, { timestamp }, { profilePicUrl }, { gitlabId })} >Submit</Button></td>
                </tr>
            )
        })
    }

    render() {
        return (
            <div>
                <Table style={{ marginTop: 20, }} >
                    <tbody>
                        <tr style={{ fontSize: 18, backgroundColor: "peru" }} >
                            <th>Email</th>
                            <th>Name</th>
                            <th>Git Username</th>
                            <th>Role</th>
                            <th>Joined</th>
                            <th>Team</th>
                            <th>Submit</th>
                        </tr>
                        {this.renderTableData()}
                    </tbody>
                </Table>
            </div>

        );
    }
}

export default UpdateUsers;