import React, { Component } from 'react';
import { render } from 'react-dom';
import './Pag.css';
import AllRequest from './AllRequest'
import ApprovePendingRequest from './ApprovePendingRequest'
import ApprovePendingGitlabCreateRequest from './ApprovePendingGitlabCreateRequest'
import PersonalRequestHisotry from './PeronalRequestHistory'
import PersonalGitlabCreateRequest from './PersonalGitlabCreateRequest'
import AllGitlabCreateRequest from './AllGitlabCreateRequest'
import PersonalKubernetesNamespaceHistory from './PersonalKubernetesNamespaceHIstory'
import ApprovePendingKubernetesNamespaceRequest from './ApprovePendingKubernetesNamespace'
import AllKubernetesNamespaceRequest from './AllKubernetesNamespaceRequest'
import AllCreateGCSBucketRequest from './AllCreateGCSBucketRequest'
import AllRevokeKubernetesNamespaceHistory from './AllRevokeKubernetesNamespaceHistory'
import ApprovePendingCreateGCSBucketRequest from './ApprovePendingCreateGCSBucketRequest'
import PersonalCreateGCSBucketHistory from './PersonalCreateGCSBucketRequestHistory'
import PersonalkubeUpscaleRequestHistory from './PersonalkubeUpscaleRequest';
import ApprovePendingK8sUpscaleRequest from './ApprovePendingKubeUpscaleRequest';
import AllK8sUpscaleRequest from './AllKubeUpscaleRequest'
import UpdateUsers from './UpdateUsers'
import ViewUsers from './ViewUsers'
import SecureStorage from 'secure-web-storage';

class Page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            start: 1,
            end: 5,
            tp: 0,
            selectedPage: 1,
            secStorage: '',
        }
        this.renderPages = this.renderPages.bind(this)
        this.setTotalPg = this.setTotalPg.bind(this)
        this.changePage = this.changePage.bind(this)
        this.left = this.left.bind(this)
        this.right = this.right.bind(this)
        this.setValue = this.setValue.bind(this)
    }

    setValue(props) {
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var email = this.state.secStorage.email
        var curl;
        if (this.props.curl === "Approve GCS Bucket Create Request") curl = baseUrl + "/gcsbucketcreate?page=0&noOfEntries=17&filter=pending&user=" + email;
        if (this.props.curl === "Personal GCS Bucket Create History") curl = baseUrl + "/gcsbucketcreate?page=0&noOfEntries=17&filter=" + email;
        if (this.props.curl === "All GCS Bucket Create Request") curl = baseUrl + "/gcsbucketcreate?page=0&noOfEntries=17&filter=all";
        if (this.props.curl === "All Gitlab Create Request") curl = baseUrl + "/gitlabcreateresource?page=0&noOfEntries=17&filter=all";
        if (this.props.curl === "All Gitlab Access Request") curl = baseUrl + "/gitrequest?page=0&noOfEntries=17&filter=all";
        if (this.props.curl === "Approve Gitlab Access Request") curl = baseUrl + "/gitrequest?page=0&noOfEntries=17&filter=pending&user=" + this.state.secStorage.gitlabId;
        if (this.props.curl === "Approve Gitlab Create Request") curl = baseUrl + "/gitlabcreateresource?page=0&noOfEntries=17&filter=pending&user=" + email;
        if (this.props.curl === "Personal Gitlab Create History") curl = baseUrl + "/gitlabcreateresource?page=0&noOfEntries=17&filter=" + email;
        if (this.props.curl === "Personal Gitlab Access History") curl = baseUrl + "/gitrequest?page=0&noOfEntries=17&filter=" + props;
        if (this.props.curl === "All K8s Namespace Access Request") curl = baseUrl + "/k8srequest?page=0&noOfEntries=17&filter=all";
        if (this.props.curl === "All K8s Revoke History") curl = baseUrl + "/k8srevoke?page=0&noOfEntries=17&filter=all";
        if (this.props.curl === "Personal K8s Namespace Access History") curl = baseUrl + "/k8srequest?page=0&noOfEntries=17&filter=" + email;
        if (this.props.curl === "Approve K8s Namespace Access Request") curl = baseUrl + "/k8srequest?page=0&noOfEntries=17&filter=pending&user=" + email;
        if (this.props.curl === "View Users") curl = baseUrl + "/users?page=0&noOfEntries=17";
        if (this.props.curl === "Update Users") curl = baseUrl + "/users?page=0&noOfEntries=17";
        if (this.props.curl === "All K8s Upscale Request") curl = baseUrl + "/k8suprequest?page=0&noOfEntries=17&filter=all";
        if (this.props.curl === "Personal K8s Upscale Request History") curl = baseUrl + "/k8suprequest?page=0&noOfEntries=17&filter=" + email;
        if (this.props.curl === "Approve K8s Upscale Request") curl = baseUrl + "/k8suprequest?page=0&noOfEntries=17&filter=pending&user=" + email;
        var currentThis = this;
        var axios = require('axios');
        var config = {
            method: 'get',
            url: curl,
            headers: {
                'Authorization': 'Basic ' + base64,
                'content-type': 'multipart/form-data'
            },
        };
        axios(config)
            .then(function (response) {
                currentThis.setTotalPg(response.data)
            })
            .catch(function (error) {
                console.log(error)
                alert("Unexpected error: Please contact admin")
            });
    }

    componentDidMount() {
        var CryptoJS = require("crypto-js");
        var SECRET_KEY = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY;
        var secureStorage = new SecureStorage(localStorage, {
            hash: function hash(key) {
                key = CryptoJS.SHA256(key, SECRET_KEY);
                return key.toString();
            },
            encrypt: function encrypt(data) {
                data = CryptoJS.AES.encrypt(data, SECRET_KEY);
                data = data.toString();
                return data;
            },
            decrypt: function decrypt(data) {
                data = CryptoJS.AES.decrypt(data, SECRET_KEY);
                data = data.toString(CryptoJS.enc.Utf8);
                return data;
            }
        });
        this.setState({ secStorage: secureStorage.getItem('UserInfo') })

        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var currentThis = this
        var axios = require('axios');
        var curl = baseUrl + '/users/' + secureStorage.getItem('UserInfo').email
        var config = {
            method: 'get',
            url: curl,
            headers: {
                'Authorization': 'Basic ' + base64,
            }
        };

        axios(config)
            .then(function (response) {
                currentThis.setValue(response.data.gitlabId)
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });
    }

    setTotalPg(props) {
        this.setState({ tp: props.totalPages })
        if (this.state.tp < 15) this.setState({ end: this.state.tp }); else this.setState({ end: 15 });
        var list = [];
        for (var i = this.state.start; i <= this.state.end; i++) {
            list[i - 1] = i;
        }
        this.setState({ list: list })
    }

    left() {
        if (this.state.start > 1) {
            this.state.start = this.state.start - 1;
            this.state.end = this.state.end - 1;
            //this.setState({ start: this.state.start - 1 })
            //this.setState({ end: this.state.end - 1 })
            var list = [];
            for (var i = 0; i < 15; i++) {
                list[i] = this.state.start + i;
            }
            this.state.list = list;
            this.setState({ list: list })
        }
    }

    right() {
        if (this.state.end < this.state.tp) {
            this.state.start = this.state.start + 1;
            this.state.end = this.state.end + 1;
            //this.setState({ start: this.state.start + 1 })
            //this.setState({ end: this.state.end + 1 })
            var list = [];
            for (var i = 0; i < 15; i++) {
                list[i] = this.state.start + i;
            }
            this.state.list = list;
            this.setState({ list: list })
        }
    }

    changePage(props) {
        this.setState({ selectedPage: props })
        if (this.props.curl === "All Gitlab Create Request") {
            render(<AllGitlabCreateRequest page={props - 1} numberOfEntries={17} />, document.getElementById('content'));
        }
        if (this.props.curl === "All Gitlab Access Request") {
            render(<AllRequest page={props - 1} numberOfEntries={17} />, document.getElementById('content'));
        }
        if (this.props.curl === "Approve Gitlab Access Request") {
            render(<ApprovePendingRequest page={props - 1} numberOfEntries={16} />, document.getElementById('content'));
        }
        if (this.props.curl === "Approve Gitlab Create Request") {
            render(<ApprovePendingGitlabCreateRequest page={props - 1} numberOfEntries={16} />, document.getElementById('content'));
        }
        if (this.props.curl === "Personal Gitlab Create History") {
            render(<PersonalGitlabCreateRequest page={props - 1} numberOfEntries={17} />, document.getElementById('content'));
        }
        if (this.props.curl === "Personal Gitlab Access History") {
            render(<PersonalRequestHisotry page={props - 1} numberOfEntries={17} />, document.getElementById('content'));
        }
        if (this.props.curl === "Personal K8s Namespace Access History") {
            render(<PersonalKubernetesNamespaceHistory page={props - 1} numberOfEntries={17} />, document.getElementById('content'));
        }
        if (this.props.curl === "Approve K8s Namespace Access Request") {
            render(<ApprovePendingKubernetesNamespaceRequest page={props - 1} numberOfEntries={16} />, document.getElementById('content'));
        }
        if (this.props.curl === "All K8s Namespace Access Request") {
            render(<AllKubernetesNamespaceRequest page={props - 1} numberOfEntries={17} />, document.getElementById('content'));
        }
        if (this.props.curl === "All GCS Bucket Create Request") {
            render(<AllCreateGCSBucketRequest page={props - 1} numberOfEntries={17} />, document.getElementById('content'));
        }
        if (this.props.curl === "All K8s Revoke History") {
            render(<AllRevokeKubernetesNamespaceHistory page={props - 1} numberOfEntries={17} />, document.getElementById('content'));
        }
        if (this.props.curl === "View Users") {
            render(<ViewUsers page={props - 1} numberOfEntries={17} />, document.getElementById('content'));
        }
        if (this.props.curl === "Update Users") {
            render(<UpdateUsers page={props - 1} numberOfEntries={17} />, document.getElementById('content'));
        }
        if (this.props.curl === "Approve GCS Bucket Create Request") {
            render(<ApprovePendingCreateGCSBucketRequest page={props - 1} numberOfEntries={16} />, document.getElementById('content'));
        }
        if (this.props.curl === "Personal GCS Bucket Create History") {
            render(<PersonalCreateGCSBucketHistory page={props - 1} numberOfEntries={17} />, document.getElementById('content'));
        }
        if (this.props.curl === "All K8s Upscale Request") {
            render(<AllK8sUpscaleRequest page={props - 1} numberOfEntries={17} />, document.getElementById('content'));
        }
        if (this.props.curl === "Approve K8s Upscale Request") {
            render(<ApprovePendingK8sUpscaleRequest page={props - 1} numberOfEntries={17} />, document.getElementById('content'));
        }
        if (this.props.curl === "Personal K8s Upscale Request History") {
            render(<PersonalkubeUpscaleRequestHistory page={props - 1} numberOfEntries={17} />, document.getElementById('content'));
        }
    }

    renderPages() {
        if (this.state.tp > 0) {
            return this.state.list.map((request) => {
                //console.log(request + ':' + this.props.curl)
                var bgcolor = "";
                if (this.state.selectedPage === request) bgcolor = "olive"; else bgcolor = "tan";
                return (
                    <div className="pagination" key={request} >
                        <a style={{ cursor: "pointer", backgroundColor: bgcolor, marginTop: -18, fontSize: 15 }} value={request} onClick={() => { this.changePage(request) }}><b>{request}</b></a>
                    </div>
                )
            });
        }

    }
    render() {
        return (
            <div className="pagination">
                <a style={{ cursor: "pointer", backgroundColor: "papayawhip", fontSize: 15, height: 38, marginTop: 2 }} onClick={this.left}><b>&laquo;</b></a>
                { this.renderPages()}
                <a style={{ cursor: "pointer", backgroundColor: "papayawhip", fontSize: 15, height: 38, marginTop: 2 }} onClick={this.right}><b>&raquo;</b></a>
            </div >
        )
    }
}

export default Page;