import React, { Component } from 'react';
import { Button, Form, Input, Table } from 'reactstrap';
import Select from 'react-select'
import SecureStorage from 'secure-web-storage';

export default class CreateGitlabProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            name: '',
            group: '',
            group_id: 0,
            invalid: true,
            groupNameId: -1,
            showProject: false,
            showGroup: false,
            secStorage: '',
        }
        this.submitHandler = this.submitHandler.bind(this)
        this.changeHandler = this.changeHandler.bind(this)
        this.handleChangeRepo = this.handleChangeRepo.bind(this)
        this.optionValue = this.optionValue.bind(this)
        this.optionValueRepo = this.optionValueRepo.bind(this)
    }

    componentDidMount() {
        var CryptoJS = require("crypto-js");
        var SECRET_KEY = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY;
        var secureStorage = new SecureStorage(localStorage, {
            hash: function hash(key) {
                key = CryptoJS.SHA256(key, SECRET_KEY);
                return key.toString();
            },
            encrypt: function encrypt(data) {
                data = CryptoJS.AES.encrypt(data, SECRET_KEY);
                data = data.toString();
                return data;
            },
            decrypt: function decrypt(data) {
                data = CryptoJS.AES.decrypt(data, SECRET_KEY);
                data = data.toString(CryptoJS.enc.Utf8);
                return data;
            }
        });
        this.setState({ secStorage: secureStorage.getItem('UserInfo') })

        var currentThis = this;
        var curl = 'https://gitlab.tfw.io/api/v4/groups?page=1&per_page=100'
        var axios = require('axios');
        var config = {
            method: 'get',
            url: curl,
            headers: {
                'PRIVATE-TOKEN': process.env.REACT_APP_GITLAB_KEY,
            },
        };
        axios(config)
            .then(function (response) {
                for (var i = 0; i < response.data.length; i++) {
                    currentThis.state.list = [...currentThis.state.list, { value: response.data[i].id, label: response.data[i].name }]
                }
                currentThis.optionValueRepo(currentThis.state.list)
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });
    }

    changeHandler = (e) => {
        this.setState({ showGroup: true })
        if (this.state.group_id > 0 & e.target.value.length > 0) this.setState({ invalid: false }); else { this.setState({ invalid: true }); this.setState({ showProject: true }) }
        this.optionValue(e.target.value)
    }

    optionValueRepo(list) {
        this.setState({ [list]: list })
    }

    optionValue(props) {
        this.setState({ name: props })
    }

    handleChangeRepo = (e) => {
        if (e.label.length > 0 & this.state.name.length > 0) this.setState({ invalid: false }); else { this.setState({ invalid: true }); }
        this.setState({ group_id: e.value })
        this.setState({ group: e.label })
        this.setState({ groupNameId: e })
    }

    submitHandler = (e) => {
        var res = this.state.name + '(' + this.state.group + ')'
        var today = new Date()
        var axios = require('axios');
        var FormData = require('form-data');
        var data = new FormData();
        let mon = today.getMonth() + 1
        data.append('user', this.state.secStorage.email);
        data.append('type', 'Gitlab Resource Create');
        data.append('group_id', this.state.group_id);
        data.append('resource', res);
        data.append('resource_id', 'TBD');
        data.append('visibility', 'private');
        data.append('approvedBy', 'Pending');
        data.append('category', 'Project');
        data.append('status', 'Pending');
        data.append('timestamp', today.getDate() + "-" + mon + "-" + today.getFullYear() + ":" + today.getHours() + ":" + today.getMinutes());
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var config = {
            method: 'post',
            url: baseUrl + '/gitlabcreateresource',
            headers: {
                'Authorization': 'Basic ' + base64,
                'content-type': 'multipart/form-data'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });
        document.getElementById("form1").reset();
        this.setState({ groupNameId: -1 })
        this.setState({ invalid: true })
        this.setState({ group_id: -1 })
        this.setState({ showGroup: false })
    }


    render() {
        return (
            <div style={{ marginLeft: 20 }}>
                <Form id="form1" name="form1">
                    <Table style={{ width: "30%", marginLeft: 400, marginTop: "20%", fontSize: 16 }}>
                        <tbody >
                            <tr>
                                <td><Select value={this.state.groupNameId} placeholder="Select Group name" isSearchable options={this.state.list} onChange={(e) => this.handleChangeRepo(e)} />
                                    {this.state.group_id <= 0 && this.state.showGroup &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>Select a Group</span>}
                                </td>

                            </tr>
                            <tr >
                                <td><Input style={{ fontSize: 16 }} type="text" placeholder="Enter Project name" onChange={(e) => this.changeHandler(e)} />
                                    {this.state.name.length <= 0 && this.state.showProject &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>Project name cannot be empty</span>}</td>
                            </tr>
                            <tr>
                                <td><Button disabled={this.state.invalid} onClick={this.submitHandler} style={{ marginLeft: 130, backgroundColor: "#33382e", color: "white", fontSize: 16 }} >Submit</Button></td>
                            </tr>
                        </tbody>
                    </Table>

                </Form>
            </div>
        );
    }
}