import '../index.css';
import React, { Component } from 'react';
import { Button, Table, } from 'reactstrap';
import { FcApprove, FcDisapprove } from 'react-icons/fc';

class ApprovePendingRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            firstRow: 1,
        }
        this.optionValue = this.optionValue.bind(this)
        this.approve = this.approve.bind(this)
        this.reject = this.reject.bind(this)
    }
    componentDidMount() {
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var currentThis = this;
        var axios = require('axios');
        var config = {
            method: 'get',
            url: baseUrl + '/users?page=0&noOfEntries=10000',
            headers: {
                'Authorization': 'Basic ' + base64,
            }
        };
        axios(config)
            .then(function (response) {
                for (var i = 0; i < response.data.content.length; i++) {
                    if (response.data.content[i].adminStatus === "Pending") {
                        currentThis.state.list = [...currentThis.state.list, response.data.content[i]]
                    }
                }
                currentThis.optionValue(currentThis.state.list)
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });

    }

    optionValue(list) {
        this.setState({ list: list })
    }

    approve(email, name, team, adminStatus, role, gitlabId, profilePicUrl, timestamp) {
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var FormData = require('form-data');
        var axios = require('axios');
        var data = new FormData();
        console.log(JSON.parse(JSON.stringify(timestamp)).timestamp)
        data.append('email', JSON.parse(JSON.stringify(email)).email);
        data.append('name', JSON.parse(JSON.stringify(name)).name);
        data.append('role', 'admin');
        data.append('team', JSON.parse(JSON.stringify(team)).team);
        data.append('profilePicUrl', JSON.parse(JSON.stringify(profilePicUrl)).profilePicUrl);
        data.append('gitlabId', JSON.parse(JSON.stringify(gitlabId)).gitlabId);
        data.append('timestamp', JSON.parse(JSON.stringify(timestamp)).timestamp);
        data.append('adminStatus', 'Completed');
        var config = {
            method: 'put',
            url: baseUrl + '/users',
            headers: {
                'Authorization': 'Basic ' + base64,
                'content-type': 'multipart/form-data'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });

        this.setState(prevState => {
            var users = [...prevState.list];
            users.map(item => {
                if (item.email === JSON.parse(JSON.stringify(email)).email) {
                    item.status = "Approved"
                }
            })
            const temp_users = users.filter(item => item.email !== JSON.parse(JSON.stringify(email)).email)
            return {
                list: temp_users
            }
        })
    }

    reject(email, name, team, adminStatus, role, gitlabId, profilePicUrl, timestamp) {
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var FormData = require('form-data');
        var axios = require('axios');
        var data = new FormData();
        data.append('email', JSON.parse(JSON.stringify(email)).email);
        data.append('name', JSON.parse(JSON.stringify(name)).name);
        data.append('role', JSON.parse(JSON.stringify(role)).role);
        data.append('team', JSON.parse(JSON.stringify(team)).team);
        data.append('profilePicUrl', JSON.parse(JSON.stringify(profilePicUrl)).profilePicUrl);
        data.append('gitlabId', JSON.parse(JSON.stringify(gitlabId)).gitlabId);
        data.append('timestamp', JSON.parse(JSON.stringify(timestamp)).timestamp);
        data.append('adminStatus', 'Rejected');
        var config = {
            method: 'put',
            url: baseUrl + '/users',
            headers: {
                'Authorization': 'Basic ' + base64,
                'content-type': 'multipart/form-data'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });

        this.setState(prevState => {
            var users = [...prevState.list];
            users.map(item => {
                if (item.email === JSON.parse(JSON.stringify(email)).email) {
                    item.status = "Rejected"
                }
            })
            const temp_users = users.filter(item => item.email !== JSON.parse(JSON.stringify(email)).email)
            return {
                list: temp_users
            }
        })
    }

    renderTableData() {
        var i = 0
        return this.state.list.map((request) => {
            const { email, name, role, team, adminStatus, gitlabId, timestamp, profilePicUrl } = request
            var bgcolor;
            if (i % 2 === 0) bgcolor = "tan"; else bgcolor = "papayawhip";
            i = i + 1;
            return (
                <tr key={email} style={{ fontSize: 14, backgroundColor: bgcolor, opacity: "75%" }}>
                    <td>{email}</td>
                    <td>{name}</td>
                    <td>{team}</td>
                    <td>
                        <div >
                            <Button onClick={() => this.approve({ email }, { name }, { team }, { adminStatus }, { role }, { gitlabId }, { profilePicUrl }, { timestamp })} style={{ display: "inline-block", float: "left", backgroundColor: "green", color: "white", width: 100 }} > <FcApprove /> Approve</Button>
                            <Button onClick={() => this.reject({ email }, { name }, { team }, { adminStatus }, { role }, { gitlabId }, { profilePicUrl }, { timestamp })} style={{ display: "inline-block", float: "left", backgroundColor: "red", color: "white", width: 100 }} > <FcDisapprove />Reject</Button>
                        </div>
                    </td>
                </tr>
            )
        })
    }
    render() {
        return (
            <div>
                <Table style={{ marginTop: 20 }} hover id="requests" >
                    <tbody>
                        <tr style={{ fontSize: 18, backgroundColor: "peru" }}>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Team</th>
                            <th>Status</th>
                        </tr>
                        {this.renderTableData()}
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default ApprovePendingRequest;