import React, { Component } from 'react';
import GoogleLogin from 'react-google-login';
import ReactDOM from 'react-dom';
import SecureStorage from 'secure-web-storage';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: false,
            role: '',
            list: [],

        }
        this.responseGoogle = this.responseGoogle.bind(this);
        this.failLogin = this.failLogin.bind(this)
    }

    failLogin(responseGoogleData) {
        //console.log(responseGoogleData)
        alert("Login Failed!")
    }

    responseGoogle = (responseGoogleData) => {
        var substr = responseGoogleData.profileObj.email.split('@')[1];
        if (substr === process.env.REACT_APP_OYO_DOMAIN || substr === process.env.REACT_APP_TRAUM_DOMAIN) {
            var CryptoJS = require("crypto-js");
            var SECRET_KEY = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY;
            var secureStorage = new SecureStorage(localStorage, {
                hash: function hash(key) {
                    key = CryptoJS.SHA256(key, SECRET_KEY);
                    return key.toString();
                },
                encrypt: function encrypt(data) {
                    data = CryptoJS.AES.encrypt(data, SECRET_KEY);
                    data = data.toString();
                    return data;
                },
                decrypt: function decrypt(data) {
                    data = CryptoJS.AES.decrypt(data, SECRET_KEY);
                    data = data.toString(CryptoJS.enc.Utf8);
                    return data;
                }
            });
            var dataStr = {};
            dataStr.email = responseGoogleData.profileObj.email;
            var today = new Date()
            var FormData = require('form-data');
            var axios = require('axios');
            var data = new FormData();
            var springCreds = process.env.REACT_APP_SPRING_CREDS;
            var base64 = btoa(springCreds);
            var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
            let mon = today.getMonth() + 1
            data.append('email', responseGoogleData.profileObj.email);
            data.append('name', responseGoogleData.profileObj.name);
            data.append('gitlabId', '');
            data.append('role', 'user');
            data.append('team', 'NA');
            data.append('adminStatus', 'Not Requested');
            data.append('profilePicUrl', responseGoogleData.profileObj.imageUrl);
            data.append('timestamp', today.getDate() + "-" + mon + "-" + today.getFullYear() + ":" + today.getHours() + ":" + today.getMinutes());
            var config = {
                method: 'post',
                url: baseUrl + '/users',
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': 'Basic ' + base64,
                },
                data: data
            };
            axios(config)
                .then(function (response) {
                    dataStr.role = 'user'
                    dataStr.firstLogin = true
                    dataStr.isAuth = true
                    dataStr.gitlabId = ""
                    secureStorage.setItem('UserInfo', dataStr);
                    setTimeout(function () { window.location.href = '/' }, 500);
                })
                .catch(function (error) {
                    if (error.response.request.status === 409) {
                        dataStr.role = error.response.data.role
                        dataStr.firstLogin = false
                        dataStr.isAuth = true
                        dataStr.gitlabId = error.response.data.gitlabId
                        secureStorage.setItem('UserInfo', dataStr);
                        setTimeout(function () { window.location.href = '/' }, 500);
                    }
                });
        }
        else {
            ReactDOM.render(<Login />, document.getElementById('sample'));
            alert("Please login with oyorooms or traum-ferienwohnungen email id")
        }
    }
    render() {
        return (
            <div >
                <div style={{ height: "100%", overflow: "auto" }}>
                    <h1 style={{ fontSize: 40, textAlign: "center", backgroundColor: "#33382e", color: "white", marginTop: 0 }}>Traum Devops</h1>
                    <div style={{ marginLeft: "42%", marginTop: "6%" }}>
                        <img alt="Loading" style={{ width: "30%", height: "30%" }} src={require('./traum-logo.png')} />
                        <br /><br /><br /><br /><br />
                        <GoogleLogin
                            clientId="908133605970-t2s5lm8kab4nioujfilhmtek37m6mmu8.apps.googleusercontent.com"
                            render={renderProps => (
                                <button style={{ fontSize: 20, backgroundColor: "#33382e", color: "white", height: 50, width: "30%" }} onClick={renderProps.onClick} >Sign in with Google</button>
                            )}
                            buttonText="Sign in with Google"
                            onSuccess={this.responseGoogle}
                            onFailure={this.failLogin}
                            cookiePolicy={'single_host_origin'}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default Login


