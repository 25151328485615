import '../index.css';
import React, { Component } from 'react';
import { Button, Table, } from 'reactstrap';
import { FcApprove, FcDisapprove } from 'react-icons/fc';
import SecureStorage from 'secure-web-storage';

class ApprovePendingRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            page: '',
            noOfEntries: '',
            st: '',
            rsn: '',
            secStorage: '',
        }
        this.optionValue = this.optionValue.bind(this)
        this.approve = this.approve.bind(this)
        this.reject = this.reject.bind(this)
    }

    componentWillReceiveProps(props) {
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var currentThis = this;
        this.setState({ list: [] })
        var axios = require('axios');
        var config = {
            method: 'get',
            url: baseUrl + '/gitrequest?page=' + props.page + '&noOfEntries=' + props.numberOfEntries + '&filter=pending&user=' + this.state.secStorage.gitlabId,
            headers: {
                'Authorization': 'Basic ' + base64,
            }
        };
        axios(config)
            .then(function (response) {
                currentThis.optionValue(response.data.content)
            })
            .catch(function (error) {
                alert(error)
            });
    }

    componentDidMount() {
        var CryptoJS = require("crypto-js");
        var SECRET_KEY = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY;
        var secureStorage = new SecureStorage(localStorage, {
            hash: function hash(key) {
                key = CryptoJS.SHA256(key, SECRET_KEY);
                return key.toString();
            },
            encrypt: function encrypt(data) {
                data = CryptoJS.AES.encrypt(data, SECRET_KEY);
                data = data.toString();
                return data;
            },
            decrypt: function decrypt(data) {
                data = CryptoJS.AES.decrypt(data, SECRET_KEY);
                data = data.toString(CryptoJS.enc.Utf8);
                return data;
            }
        });
        this.setState({ secStorage: secureStorage.getItem('UserInfo') })

        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var currentThis = this;
        var axios = require('axios');
        var config = {
            method: 'get',
            url: baseUrl + '/gitrequest?page=' + this.props.page + '&noOfEntries=' + this.props.numberOfEntries + '&filter=pending&user=' + secureStorage.getItem('UserInfo').gitlabId,
            headers: {
                'Authorization': 'Basic ' + base64,
            }
        };
        axios(config)
            .then(function (response) {
                currentThis.optionValue(response.data.content)
            })
            .catch(function (error) {
                alert(error)
            });
    }

    optionValue(list) {
        this.setState({ list: list })
    }

    approve(id, username, description, resource_id, resource, access_level_id, acc_level, timestamp, modify) {
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var axios = require('axios');
        var FormData = require('form-data');
        var data = new FormData();
        data.append('id', JSON.parse(JSON.stringify(id)).id);
        data.append('username', JSON.parse(JSON.stringify(username)).username);
        data.append('description', JSON.parse(JSON.stringify(description)).description);
        data.append('resource_id', JSON.parse(JSON.stringify(resource_id)).resource_id);
        data.append('resource', JSON.parse(JSON.stringify(resource)).resource);
        data.append('acc_level_id', JSON.parse(JSON.stringify(access_level_id)).acc_level_id);
        data.append('approvedBy', this.state.secStorage.email.split('@')[0]);
        data.append('acc_level', JSON.parse(JSON.stringify(acc_level)).acc_level);
        data.append('status', "Approved");
        data.append('failReason', "Approved")
        data.append('timestamp', JSON.parse(JSON.stringify(timestamp)).timestamp);

        var config = {
            method: 'put',
            url: baseUrl + '/gitrequest',
            headers: {
                'Authorization': 'Basic ' + base64,
                'content-type': 'multipart/form-data'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                //console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                alert(error)
            });
        this.setState(prevState => {
            var users = [...prevState.list];
            users.map(item => {
                if (item.id === JSON.parse(JSON.stringify(id)).id) {
                    item.status = "Approved"
                }
            })
            const temp_users = users.filter(item => item.id !== JSON.parse(JSON.stringify(id)).id)
            return {
                list: temp_users
            }
        })
    }

    reject(id, username, description, resource_id, resource, access_level_id, acc_level, timestamp, modify) {
        var reason = prompt("Please mention reason for rejection", "Rejected");
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var axios = require('axios');
        var FormData = require('form-data');
        var data = new FormData();
        data.append('id', JSON.parse(JSON.stringify(id)).id);
        data.append('username', JSON.parse(JSON.stringify(username)).username);
        data.append('description', JSON.parse(JSON.stringify(description)).description);
        data.append('resource_id', JSON.parse(JSON.stringify(resource_id)).resource_id);
        data.append('resource', JSON.parse(JSON.stringify(resource)).resource);
        data.append('acc_level_id', JSON.parse(JSON.stringify(access_level_id)).acc_level_id);
        data.append('acc_level', JSON.parse(JSON.stringify(acc_level)).acc_level);
        data.append('approvedBy', this.state.secStorage.email.split('@')[0]);
        data.append('failReason', reason)
        data.append('status', 'Rejected');
        data.append('timestamp', JSON.parse(JSON.stringify(timestamp)).timestamp);
        var config = {
            method: 'put',
            url: baseUrl + '/gitrequest',
            headers: {
                'Authorization': 'Basic ' + base64,
                'content-type': 'multipart/form-data'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
            })
            .catch(function (error) {
                alert(error)
                //console.log(error);
            });
        this.setState(prevState => {
            var users = [...prevState.list];
            users.map(item => {
                if (item.id === JSON.parse(JSON.stringify(id)).id) {
                    item.status = "Rejected"
                }
            })
            const temp_users = users.filter(item => item.id !== JSON.parse(JSON.stringify(id)).id)
            return {
                list: temp_users
            }
        })
    }

    renderTableData() {
        var i = 0
        return this.state.list.map((request) => {
            const { id, username, description, resource_id, resource, acc_level_id, acc_level, timestamp, modify } = request
            var bgcolor;
            if (i % 2 === 0) bgcolor = "tan"; else bgcolor = "papayawhip";
            i = i + 1;
            return (
                <tr key={id} style={{ fontSize: 14, backgroundColor: bgcolor, opacity: "75%" }}>
                    <td>{username}</td>
                    <td>{description}</td>
                    <td>{resource}</td>
                    <td>{acc_level}</td>
                    <td>{timestamp}</td>
                    <td>
                        <div >
                            <Button onClick={() => this.approve({ id }, { username }, { description }, { resource_id }, { resource }, { acc_level_id }, { acc_level }, { timestamp }, { modify })} style={{ display: "inline-block", float: "left", backgroundColor: "green", color: "white", width: 100 }} > <FcApprove /> Approve</Button>
                            <Button onClick={() => this.reject({ id }, { username }, { description }, { resource_id }, { resource }, { acc_level_id }, { acc_level }, { timestamp }, { modify })} style={{ display: "inline-block", float: "left", backgroundColor: "red", color: "white", width: 100 }} > <FcDisapprove />Reject</Button>
                        </div>
                    </td>
                </tr>
            )
        })
    }
    render() {
        return (
            <div>
                <Table style={{ marginTop: 20 }} hover id="requests" >
                    <tbody>
                        <tr style={{ fontSize: 18, backgroundColor: "peru" }}>
                            <th>Username</th>
                            <th>Type</th>
                            <th>Resource</th>
                            <th>Access Level</th>
                            <th>Timestamp</th>
                            <th>Status</th>
                        </tr>
                        {this.renderTableData()}
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default ApprovePendingRequest;