import React, { Component } from 'react'
import SecureStorage from 'secure-web-storage';

export default class Logout extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        var CryptoJS = require("crypto-js");
        var SECRET_KEY = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY;
        var secureStorage = new SecureStorage(localStorage, {
            hash: function hash(key) {
                key = CryptoJS.SHA256(key, SECRET_KEY);
                return key.toString();
            },
            encrypt: function encrypt(data) {
                data = CryptoJS.AES.encrypt(data, SECRET_KEY);
                data = data.toString();
                return data;
            },
            decrypt: function decrypt(data) {
                data = CryptoJS.AES.decrypt(data, SECRET_KEY);
                data = data.toString(CryptoJS.enc.Utf8);
                return data;
            }
        });

        secureStorage.clear();
        return (
            <div >
                <div style={{ height: "100%", overflow: "auto" }}>
                    <h1 style={{ fontSize: 40, textAlign: "center", backgroundColor: "#33382e", color: "white", marginTop: 0 }}>Traum Devops</h1>
                    <div style={{ marginLeft: "42%", marginTop: "6%" }}>
                        <img alt="Loading" style={{ width: "30%", height: "30%" }} src={require('./traum-logo.png')} />
                        <br /><br /><br /><br /><br />
                        <h2>You have been signed out!</h2>
                        <a style={{ fontSize: 17, marginLeft: 70 }} href="/login">Back to Login</a>
                    </div>
                </div>
            </div>
        )
    }
}