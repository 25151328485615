import '../index.css';
import React, { Component, } from 'react';
import { Table, } from 'reactstrap';

class ViewUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            val: "",
            page: '',
            noOfEntries: '',
        }
        this.optionValue = this.optionValue.bind(this)
    }

    componentWillReceiveProps(props) {
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var currentThis = this;
        this.setState({ list: [] })
        var axios = require('axios');
        var config = {
            method: 'get',
            url: baseUrl + '/users?page=' + props.page + '&noOfEntries=' + props.numberOfEntries,
            headers: {
                'Authorization': 'Basic ' + base64,
            }
        };
        axios(config)
            .then(function (response) {
                currentThis.optionValue(response.data.content)
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });
    }

    componentDidMount() {
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var currentThis = this;
        var axios = require('axios');
        var config = {
            method: 'get',
            url: baseUrl + '/users?page=' + this.props.page + '&noOfEntries=' + this.props.numberOfEntries,
            headers: {
                'Authorization': 'Basic ' + base64,
            }
        };
        axios(config)
            .then(function (response) {
                currentThis.optionValue(response.data.content)
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });
    }

    optionValue(list) {
        this.setState({ list: list })
    }

    renderTableData() {
        var i = 0;
        return this.state.list.map((request) => {
            const { email, name, role, team, adminStatus, timestamp, gitlabId } = request
            var bgcolor;
            if (i % 2 === 0) bgcolor = "tan"; else bgcolor = "papayawhip";
            i = i + 1;
            return (
                <tr key={email} style={{ fontSize: 14, backgroundColor: bgcolor, opacity: "75%" }}>
                    <td>{email}</td>
                    <td>{name}</td>
                    <td>{gitlabId}</td>
                    <td>{role}</td>
                    <td>{team}</td>
                    <td>{timestamp}</td>
                </tr>
            )
        })
    }

    render() {
        return (
            <div>
                <Table style={{ marginTop: 20, }} >
                    <tbody>
                        <tr style={{ fontSize: 18, backgroundColor: "peru" }} >
                            <th>Email</th>
                            <th>Name</th>
                            <th>Git Username</th>
                            <th>Role</th>
                            <th>Team</th>
                            <th>Joined</th>
                        </tr>
                        {this.renderTableData()}
                    </tbody>
                </Table>
            </div>

        );
    }
}

export default ViewUsers;