import React, { Component } from 'react'
import { Button, Input, Table, } from 'reactstrap';
import Select from 'react-select'
import SecureStorage from 'secure-web-storage';

const option = [
    { value: 'Devops', label: 'Devops' },
    { value: 'Revenue Management', label: 'Revenue Management' },
    { value: 'Search & SEO', label: 'Search & SEO' },
    { value: 'Bookings & Inquiries', label: 'Bookings & Inquiries' },
    { value: 'Consumer Experience', label: 'Consumer Experience' },
    { value: 'FE-Demand', label: 'FE-Demand' },
    { value: 'FE-Supply', label: 'FE-Supply' },
    { value: 'Core Supply', label: 'Core Supply' },
    { value: 'Connectivity & Channeling', label: 'Connectivity & Channeling' },
    { value: 'Platform', label: 'Platform' },
    { value: 'Data Team', label: 'Data Team' },
    { value: 'Tech Support', label: 'Tech Support' },
    { value: 'IT Support', label: 'IT Support' }
]

export default class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            team: '',
            gitlabId: '',
            secStorage: '',
        }
        this.optionValue = this.optionValue.bind(this)
        this.handleChangeGitlabId = this.handleChangeGitlabId.bind(this)
        this.handleChangeTeam = this.handleChangeTeam.bind(this)
        this.submitHandler = this.submitHandler.bind(this)
    }

    componentDidMount() {
        var CryptoJS = require("crypto-js");
        var SECRET_KEY = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY;
        var secureStorage = new SecureStorage(localStorage, {
            hash: function hash(key) {
                key = CryptoJS.SHA256(key, SECRET_KEY);
                return key.toString();
            },
            encrypt: function encrypt(data) {
                data = CryptoJS.AES.encrypt(data, SECRET_KEY);
                data = data.toString();
                return data;
            },
            decrypt: function decrypt(data) {
                data = CryptoJS.AES.decrypt(data, SECRET_KEY);
                data = data.toString(CryptoJS.enc.Utf8);
                return data;
            }
        });
        this.setState({ secStorage: secureStorage.getItem('UserInfo') })

        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var currentThis = this;
        var axios = require('axios');
        var email = secureStorage.getItem('UserInfo').email
        var curl = baseUrl + '/users/' + email
        var config = {
            method: 'get',
            url: curl,
            headers: {
                'Authorization': 'Basic ' + base64,
            }
        };

        axios(config)
            .then(function (response) {
                currentThis.optionValue(response.data)
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });
    }

    optionValue(list) {
        this.setState({ list: list })
        if (this.state.secStorage.firstLogin == true & this.state.gitlabId == '' & this.state.list.gitlabId == '') alert("Please update Gitlab ID. This option will not be available later!")
    }

    handleChangeGitlabId(e) {
        this.setState({ gitlabId: e.target.value })
    }

    handleChangeTeam(e) {
        this.setState({ team: e.value })
    }

    submitHandler() {
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var git
        if (this.state.gitlabId == '') git = this.state.list.gitlabId; else git = this.state.gitlabId;
        var team
        if (this.state.team == '') team = 'NA'; else team = this.state.team;
        var FormData = require('form-data');
        var axios = require('axios');
        var data = new FormData();
        data.append('email', this.state.list.email);
        data.append('name', this.state.list.name);
        data.append('gitlabId', git);
        data.append('role', this.state.list.role);
        data.append('team', team);
        data.append('adminStatus', this.state.list.adminStatus);
        data.append('profilePicUrl', this.state.list.profilePicUrl);
        data.append('timestamp', this.state.list.timestamp);
        var config = {
            method: 'put',
            url: baseUrl + '/users',
            headers: {
                'Authorization': 'Basic ' + base64,
                'content-type': 'multipart/form-data'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                //console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                alert(error)
                //console.log(error);
            });
        alert("Info Updated!")
    }

    render() {
        var bool;
        if (this.state.list.team) {
            if (this.state.secStorage.firstLogin === true && !(this.state.list.gitlabId.length > 0)) bool = false; else bool = true;
            return (
                <div>
                    <img style={{ marginLeft: "43%", marginTop: "7%", width: "13%", height: "13%" }} src={this.state.list.profilePicUrl} alt="new" />
                    <Table style={{ marginTop: "5%", marginLeft: "30%", width: "42%", fontSize: 20 }} hover id="requests" >
                        <tbody>
                            <tr>
                                <th >Email</th>
                                <td>{this.state.list.email}</td>
                            </tr>
                            <tr>
                                <th>Name</th>
                                <td>{this.state.list.name}</td>
                            </tr>
                            <tr>
                                <th >Gitlab Username</th>
                                <td><Input readOnly={bool} type="text" defaultValue={this.state.list.gitlabId} style={{ width: 200 }} onChange={(e) => this.handleChangeGitlabId(e)} /></td>
                            </tr>
                            <tr>
                                <th>Role</th>
                                <td>{this.state.list.role}</td>
                            </tr>
                            <tr>
                                <th>Team</th>
                                <td style={{ fontSize: 13, height: 10 }} ><Select defaultInputValue={this.state.list.team} defaultValue={this.state.list.team} options={option} onChange={(e) => this.handleChangeTeam(e)} /></td>
                            </tr>
                            <tr>
                                <td colSpan={2}><Button onClick={this.submitHandler} style={{ fontSize: 16, marginLeft: 130, backgroundColor: "#33382e", color: "white", marginTop: "5%" }} >Submit</Button></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            )
        }
        else {
            return (
                <div></div>
            )
        }
    }
}