import GitRequest from './GitRequest'
import { render, unmountComponentAtNode } from 'react-dom';
import React from 'react';
import ApprovePendingRequest from './ApprovePendingRequest'
import AllRequest from './AllRequest'
import PersonalGitlabCreateRequest from './PersonalGitlabCreateRequest'
import AllGitlabCreateRequest from './AllGitlabCreateRequest'
import ApprovePendingGitlabCreateRequest from './ApprovePendingGitlabCreateRequest'
import PersonalRequestHistory from './PeronalRequestHistory'
import Page from './Page'
import KubernetesNamespace from './KubernetesNamespace'
import CreateGitlabGroup from './CreateGitlabGroup'
import CreateGitlabProject from './CreateGitlabProject'
import KubeUpscaleRequest from './KubeUpscaleRequest'
import ViewAnnouncement from './ViewAnnouncement'
import AddAnnouncement from './AddAnnouncement'
import PersonalKubernetesNamespaceHistory from './PersonalKubernetesNamespaceHIstory'
import PersonalkubeUpscaleRequestHistory from './PersonalkubeUpscaleRequest'
import ApprovePendingKubernetesNamespace from './ApprovePendingKubernetesNamespace'
import AllKubernetesNamespaceRequest from './AllKubernetesNamespaceRequest'
import AllK8sUpscaleRequest from './AllKubeUpscaleRequest'
import ApprovePendingK8sUpscaleRequest from './ApprovePendingKubeUpscaleRequest'
import ViewUsers from './ViewUsers'
import UpdateUsers from './UpdateUsers'
import CreateGCSBucket from './CreateGCSBucket'
import ApprovePendingCreateGCSBucketRequest from './ApprovePendingCreateGCSBucketRequest'
import PersonalCreateGCSBucketHistory from './PersonalCreateGCSBucketRequestHistory'
import Profile from './Profile'
import ApproveAdminRequest from './ApproveAdminRequest'
import ViewAdmins from './ViewAdmins'
import AllCreateGCSBucketRequest from './AllCreateGCSBucketRequest'
import AllRevokeKubernetesNamespaceHistory from './AllRevokeKubernetesNamespaceHistory'
import RevokeKubernetesNamespaceAccess from './RevokeKubernetesNamespaceAccess'
import SecureStorage from 'secure-web-storage';

export function OptionSelect(props) {
    if (props === "Gitlab Repositories") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<GitRequest />, document.getElementById('content'));
    }
    if (props === "Approve Gitlab Access Request") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<ApprovePendingRequest page={0} numberOfEntries={67} />, document.getElementById('content'));
        render(<Page curl={props} />, document.getElementById('footer'));
    }
    if (props === "Upscale") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<KubeUpscaleRequest />, document.getElementById('content'));
    }

    if (props === "All Gitlab Access Request") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<AllRequest page={0} numberOfEntries={17} />, document.getElementById('content'));
        render(<Page curl={props} />, document.getElementById('footer'));
    }
    if (props === "Personal Gitlab Access History") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<PersonalRequestHistory page={0} numberOfEntries={17} />, document.getElementById('content'));
        render(<Page curl={props} />, document.getElementById('footer'));
    }//all personal k8s upscale
    if (props === "Personal K8s Upscale Request History") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<PersonalkubeUpscaleRequestHistory page={0} numberOfEntries={17} />, document.getElementById('content'));
        render(<Page curl={props} />, document.getElementById('footer'));
    }
    if (props === "Approve Gitlab Create Request") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<ApprovePendingGitlabCreateRequest page={0} numberOfEntries={16} />, document.getElementById('content'));
        render(<Page curl={props} />, document.getElementById('footer'));
    }
    if (props === "All K8s Namespace Access Request") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<AllKubernetesNamespaceRequest page={0} numberOfEntries={17} />, document.getElementById('content'));
        render(<Page curl={props} />, document.getElementById('footer'));
    }//all k8s upscale
    if (props === "All K8s Upscale Request") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<AllK8sUpscaleRequest page={0} numberOfEntries={17} />, document.getElementById('content'));
        render(<Page curl={props} />, document.getElementById('footer'));
    }
    if (props === "All K8s Revoke History") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<AllRevokeKubernetesNamespaceHistory page={0} numberOfEntries={17} />, document.getElementById('content'));
        render(<Page curl={props} />, document.getElementById('footer'));
    }
    if (props === "Personal K8s Namespace Access History") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<PersonalKubernetesNamespaceHistory page={0} numberOfEntries={17} />, document.getElementById('content'));
        render(<Page curl={props} />, document.getElementById('footer'));
    }
    if (props === "Approve K8s Namespace Access Request") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<ApprovePendingKubernetesNamespace page={0} numberOfEntries={16} />, document.getElementById('content'));
        render(<Page curl={props} />, document.getElementById('footer'));
    }//approve k8s upscale request
    if (props === "Approve K8s Upscale Request") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<ApprovePendingK8sUpscaleRequest page={0} numberOfEntries={16} />, document.getElementById('content'));
        render(<Page curl={props} />, document.getElementById('footer'));
    }
    if (props === "Personal GCS Bucket Create History") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<PersonalCreateGCSBucketHistory page={0} numberOfEntries={17} />, document.getElementById('content'));
        render(<Page curl={props} />, document.getElementById('footer'));
    }
    if (props === "Approve GCS Bucket Create Request") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<ApprovePendingCreateGCSBucketRequest page={0} numberOfEntries={16} />, document.getElementById('content'));
        render(<Page curl={props} />, document.getElementById('footer'));
    }
    if (props === "All Gitlab Create Request") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<AllGitlabCreateRequest page={0} numberOfEntries={17} />, document.getElementById('content'));
        render(<Page curl={props} />, document.getElementById('footer'));
    }
    if (props === "All GCS Bucket Create Request") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<AllCreateGCSBucketRequest page={0} numberOfEntries={17} />, document.getElementById('content'));
        render(<Page curl={props} />, document.getElementById('footer'));
    }
    if (props === "Personal Gitlab Create History") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<PersonalGitlabCreateRequest page={0} numberOfEntries={17} />, document.getElementById('content'));
        render(<Page curl={props} />, document.getElementById('footer'));
    }
    if (props === "Kubernetes Namespace") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<KubernetesNamespace />, document.getElementById('content'));
    }
    if (props === "GCS Bucket") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<CreateGCSBucket />, document.getElementById('content'));
    }
    if (props === "Revoke K8s Namespace Access") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<RevokeKubernetesNamespaceAccess />, document.getElementById('content'));
    }
    if (props === "Project") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<CreateGitlabProject />, document.getElementById('content'));
    }
    if (props === "Group") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<CreateGitlabGroup />, document.getElementById('content'));
    }
    if (props === "View Announcement") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<ViewAnnouncement />, document.getElementById('content'));
    }
    if (props === "Add Announcement") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<AddAnnouncement />, document.getElementById('content'));
    }
    if (props === "View Users") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<ViewUsers page={0} numberOfEntries={17} />, document.getElementById('content'));
        render(<Page curl={props} />, document.getElementById('footer'));
    }
    if (props === "View Admins") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<ViewAdmins />, document.getElementById('content'));
    }
    if (props === "Update Users") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<UpdateUsers page={0} numberOfEntries={17} />, document.getElementById('content'));
        render(<Page curl={props} />, document.getElementById('footer'));
    }
    if (props === "Profile") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<Profile />, document.getElementById('content'));
    }
    if (props === "Approve Admin Request") {
        unmountComponentAtNode(document.getElementById('footer'));
        render(<ApproveAdminRequest />, document.getElementById('content'));
    }
    if (props === "Sign out") {
        window.location.href = '/logout'
    }
    if (props === "Request Admin Role") {
        var CryptoJS = require("crypto-js");
        var SECRET_KEY = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY;
        var secureStorage = new SecureStorage(localStorage, {
            hash: function hash(key) {
                key = CryptoJS.SHA256(key, SECRET_KEY);
                return key.toString();
            },
            encrypt: function encrypt(data) {
                data = CryptoJS.AES.encrypt(data, SECRET_KEY);
                data = data.toString();
                return data;
            },
            decrypt: function decrypt(data) {
                data = CryptoJS.AES.decrypt(data, SECRET_KEY);
                data = data.toString(CryptoJS.enc.Utf8);
                return data;
            }
        });


        var r = window.confirm("Confirm Admin Role Request?");
        if (r === true) {
            var springCreds = process.env.REACT_APP_SPRING_CREDS;
            var base64 = btoa(springCreds);
            var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
            var axios1 = require('axios');
            var email = secureStorage.getItem('UserInfo').email
            var curl = baseUrl + '/users/' + email
            var config1 = {
                method: 'get',
                url: curl,
                headers: {}
            };
            axios1(config1)
                .then(function (response) {
                    console.log(response.data)
                    var FormData = require('form-data');
                    var axios = require('axios');
                    var data = new FormData();
                    data.append('email', response.data.email);
                    data.append('gitlabId', response.data.gitlabId);
                    data.append('name', response.data.name);
                    data.append('role', response.data.role);
                    data.append('team', response.data.team);
                    data.append('adminStatus', 'Pending');
                    data.append('profilePicUrl', response.data.profilePicUrl);
                    data.append('timestamp', response.data.timestamp);
                    var config = {
                        method: 'put',
                        url: baseUrl + '/users',
                        headers: {
                            'content-type': 'multipart/form-data'
                        },
                        data: data
                    };
                    axios(config)
                        .then(function (response) {
                            console.log(JSON.stringify(response.data));
                        })
                        .catch(function (error) {
                            alert(error)
                            console.log(error);
                        });
                })
                .catch(function (error) {
                    alert(error)
                    console.log(error);
                });
        }
    }
}

export default OptionSelect