import '../index.css';
import React, { Component } from 'react';
import { Table, } from 'reactstrap';
import SecureStorage from 'secure-web-storage';

class PersonalCreateGCSBucketHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            val: "",
            page: '',
            noOfEntries: '',
            secStorage: '',
        }
        this.optionValue = this.optionValue.bind(this)
        this.showReason = this.showReason.bind(this)
    }

    componentWillReceiveProps(props) {
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var currentThis = this;
        this.setState({ list: [] })
        var axios = require('axios');
        var config = {
            method: 'get',
            url: baseUrl + '/gcsbucketcreate?page=' + props.page + '&noOfEntries=' + props.numberOfEntries + '&filter=' + this.state.secStorage.email,
            headers: {
                'Authorization': 'Basic ' + base64,
                'content-type': 'multipart/form-data'
            },
        };
        axios(config)
            .then(function (response) {
                currentThis.optionValue(response.data.content)
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });
    }

    componentDidMount() {
        var CryptoJS = require("crypto-js");
        var SECRET_KEY = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY;
        var secureStorage = new SecureStorage(localStorage, {
            hash: function hash(key) {
                key = CryptoJS.SHA256(key, SECRET_KEY);
                return key.toString();
            },
            encrypt: function encrypt(data) {
                data = CryptoJS.AES.encrypt(data, SECRET_KEY);
                data = data.toString();
                return data;
            },
            decrypt: function decrypt(data) {
                data = CryptoJS.AES.decrypt(data, SECRET_KEY);
                data = data.toString(CryptoJS.enc.Utf8);
                return data;
            }
        });
        this.setState({ secStorage: secureStorage.getItem('UserInfo') })

        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var currentThis = this;
        var axios = require('axios');
        var config = {
            method: 'get',
            url: baseUrl + '/gcsbucketcreate?page=' + this.props.page + '&noOfEntries=' + this.props.numberOfEntries + '&filter=' + secureStorage.getItem('UserInfo').email,
            headers: {
                'Authorization': 'Basic ' + base64,
                'content-type': 'multipart/form-data'
            },
        };
        axios(config)
            .then(function (response) {
                currentThis.optionValue(response.data.content)
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });
    }

    optionValue(list) {
        this.setState({ list: list })
    }

    showReason(status, failReason) {
        if (status === "Completed")
            alert(status)
        else if (status === "Failed")
            alert("Please share the link with DevOps:" + failReason)
        else if (status === "Rejected")
            alert(failReason)
    }

    renderTableData() {
        var i = 0
        return this.state.list.map((request) => {
            const { id, username, environment, service, team, status, timestamp, approvedBy, failReason } = request
            if (status === "Approved" || status === "Completed") this.state.val = "green"; else if (status === "Rejected" || status === "Failed") this.state.val = "red"; else this.state.val = "black";
            var bgcolor;
            if (i % 2 === 0) bgcolor = "tan"; else bgcolor = "papayawhip";
            i = i + 1;
            var bucketName = environment + "-" + team + "-" + service;
            return (
                <tr key={id} style={{ fontSize: 14, backgroundColor: bgcolor, opacity: "75%" }}>
                    <td>{username}</td>
                    <td>{bucketName}</td>
                    <td onClick={() => this.showReason(status, failReason)} style={{ color: this.state.val, cursor: "pointer" }}><b>{status}</b></td>
                    <td>{approvedBy}</td>
                    <td>{timestamp}</td>
                </tr>
            )
        })
    }
    render() {
        return (
            <div>
                <Table style={{ marginTop: 20 }} hover>
                    <tbody>
                        <tr style={{ fontSize: 18, backgroundColor: "peru" }} >
                            <th>Username</th>
                            <th>Bucket Name</th>
                            <th>Status</th>
                            <th>Reviewer</th>
                            <th>Timestamp</th>
                        </tr>
                        {this.renderTableData()}
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default PersonalCreateGCSBucketHistory;