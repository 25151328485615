import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import App from '../App'
import SecureStorage from 'secure-web-storage';

const PrivateRoute = ({ component: Component, ...rest }) => {
    var CryptoJS = require("crypto-js");
    var SECRET_KEY = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY;
    var secureStorage = new SecureStorage(localStorage, {
        hash: function hash(key) {
            key = CryptoJS.SHA256(key, SECRET_KEY);
            return key.toString();
        },
        encrypt: function encrypt(data) {
            data = CryptoJS.AES.encrypt(data, SECRET_KEY);
            data = data.toString();
            return data;
        },
        decrypt: function decrypt(data) {
            data = CryptoJS.AES.decrypt(data, SECRET_KEY);
            data = data.toString(CryptoJS.enc.Utf8);
            return data;
        }
    });
    return (
        <Route {...rest} render={props => (
            secureStorage.getItem('UserInfo') ?
                <App {...props} />
                : <Redirect to="/login" />
        )} />
    );
};

export default PrivateRoute;