import '../index.css';
import React, { Component, } from 'react';
import { Table, } from 'reactstrap';

class ViewAdmins extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            val: "",
        }
        this.optionValue = this.optionValue.bind(this)
    }


    componentDidMount() {
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var currentThis = this;
        var axios = require('axios');
        var config = {
            method: 'get',
            url: baseUrl + '/users?page=0&noOfEntries=100',
            headers: {
                'Authorization': 'Basic ' + base64,
            }
        };
        axios(config)
            .then(function (response) {
                currentThis.optionValue(response.data.content)
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });
    }

    optionValue(list) {
        this.setState({ list: list })
    }

    renderTableData() {
        var i = 0;
        return this.state.list.map((request) => {
            const { email, name, role, team, adminStatus, timestamp, gitlabId } = request
            if (role === "admin") {
                var bgcolor;
                if (i % 2 === 0) bgcolor = "tan"; else bgcolor = "papayawhip";
                i = i + 1;
                return (
                    <tr key={email} style={{ fontSize: 14, backgroundColor: bgcolor, opacity: "75%" }}>
                        <td>{email}</td>
                        <td>{name}</td>
                        <td>{team}</td>
                    </tr>
                )
            }
        })
    }

    render() {
        return (
            <div>
                <Table style={{ marginTop: 20, }} >
                    <tbody>
                        <tr style={{ fontSize: 18, backgroundColor: "peru" }} >
                            <th>Email</th>
                            <th>Name</th>
                            <th>Team</th>
                        </tr>
                        {this.renderTableData()}
                    </tbody>
                </Table>
            </div>

        );
    }
}

export default ViewAdmins;