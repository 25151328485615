import React, { Component, } from 'react';
import { Button, Form, Table, Input, Label } from 'reactstrap';
import Select from 'react-select'
import '../index.css';
import SecureStorage from 'secure-web-storage';

const option = [
    { value: 'project', label: 'Project' },
    { value: 'group', label: 'Group' }
]
const accessLevels = [
    { value: '10', label: 'Guest' },
    { value: '20', label: 'Reporter' },
    { value: '30', label: 'Developer' },
    { value: '40', label: 'Maintainer' }
]

class GitRequest extends Component {

    constructor(props) {
        super(props)
        this.state = {
            list: [],
            users: [],
            user_id: '',
            user_name: '',
            category: '',
            repo_id: '',
            repo: '',
            access_level_id: '',
            access_level: '',
            invalid: true,
            userReset: -1,
            repoReset: -1,
            categoryReset: -1,
            accLevelReset: 1,
            userDataList: [],
            modify: 'unmodified',
            checked: false,
            checkLoading: false,
            secStorage: '',
        }
        this.handleChange = this.handleChange.bind(this)
        this.optionValue = this.optionValue.bind(this)
        this.submitHandler = this.submitHandler.bind(this)
        //this.onChangeModify = this.onChangeModify.bind(this)
    }
    handleChange = (e) => {
        this.setState({ checkLoading: true })
        this.setState({ categoryReset: e })
        var curl;
        this.state.list = []
        var currentThis = this;
        if (e.value === "project") {
            curl = 'https://gitlab.tfw.io/api/v4/projects'
        }
        else if (e.value === "group") {
            curl = 'https://gitlab.tfw.io/api/v4/groups?page=1&per_page=100'
        }
        var axios = require('axios');
        var config = {
            method: 'get',
            url: curl,
            headers: {
                'PRIVATE-TOKEN': process.env.REACT_APP_GITLAB_KEY,
            },
        };
        axios(config)
            .then(function (response) {
                if (e.value === "project") {
                    var springCreds = process.env.REACT_APP_SPRING_CREDS;
                    var base64 = btoa(springCreds);
                    var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
                    var axios1 = require('axios');
                    var config1 = {
                        method: 'get',
                        url: baseUrl + '/project_lists',
                        headers: {
                            'Authorization': 'Basic ' + base64,
                        }
                    };
                    axios1(config1)
                        .then(function (response) {
                            for (var i = 0; i < response.data.length; i++) {
                                currentThis.state.list = [...currentThis.state.list, { value: response.data[i].id, label: response.data[i].name }]
                            }
                            currentThis.optionValue(currentThis.state.list, "repo", e.label)
                        })
                        .catch(function (error) {
                            alert(error)
                            console.log(error);
                        });
                }
                else if (e.value === "group") {
                    var all = [];
                    for (var i = 0; i < response.data.length; i++) {
                        all[i] = response.data[i].name
                        currentThis.state.list = [...currentThis.state.list, { value: response.data[i].id, label: all[i] }]
                    }
                    currentThis.optionValue(currentThis.state.list, "repo", e.label)
                };
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });
    }

    optionValue(list, choice, categ) {
        if (choice === "repo") {
            this.setState({ list: list })
            this.setState({ category: categ })
            this.setState({ checkLoading: false })
        }
        else if (choice === "user") {
            this.setState({ users: list })
        }
    }

    componentDidMount() {
        var CryptoJS = require("crypto-js");
        var SECRET_KEY = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY;
        var secureStorage = new SecureStorage(localStorage, {
            hash: function hash(key) {
                key = CryptoJS.SHA256(key, SECRET_KEY);
                return key.toString();
            },
            encrypt: function encrypt(data) {
                data = CryptoJS.AES.encrypt(data, SECRET_KEY);
                data = data.toString();
                return data;
            },
            decrypt: function decrypt(data) {
                data = CryptoJS.AES.decrypt(data, SECRET_KEY);
                data = data.toString(CryptoJS.enc.Utf8);
                return data;
            }
        });
        this.setState({ secStorage: secureStorage.getItem('UserInfo') })

        var currentThis = this;
        var i;
        for (i = 1; i <= 2; i++) {
            var curl1 = 'https://gitlab.tfw.io/api/v4/users?page=' + i + '&per_page=100'
            //console.log(curl1)
            var axios1 = require('axios');
            var config1 = {
                method: 'get',
                url: curl1,
                headers: {
                    'PRIVATE-TOKEN': process.env.REACT_APP_GITLAB_KEY,
                },
            };
            axios1(config1)
                .then(function (response1) {
                    //console.log(response1.data)
                    for (var i = 0; i < response1.data.length; i++) {
                        currentThis.state.users = [...currentThis.state.users, { value: response1.data[i].id, label: response1.data[i].username, }]
                    }
                    currentThis.optionValue(currentThis.state.users, "user")
                })
                .catch(function (error) {
                    alert(error)
                    console.log(error);
                });
            //console.log(this.state.list)
        }

        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var axios = require('axios');
        var curl = baseUrl + '/users/' + secureStorage.getItem('UserInfo').email
        var config = {
            method: 'get',
            url: curl,
            headers: {
                'Authorization': 'Basic ' + base64,
            }
        };

        axios(config)
            .then(function (response) {
                currentThis.setState({ userDataList: response.data })
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });

    }

    handleChangeUser = (e) => {
        if (this.state.secStorage.role === 'user') {
            if (this.state.repo.length > 0 & this.state.access_level.length > 0) this.setState({ invalid: false }); else this.setState({ invalid: true });
            this.setState({ user_name: this.state.userDataList.gitlabId })
            this.setState({ user_id: this.state.userDataList.gitlabId })
        }
        else {
            if (e.label.length > 0 & this.state.repo.length > 0 & this.state.access_level.length > 0) this.setState({ invalid: false }); else this.setState({ invalid: true });
            this.setState({ user_name: e.label })
            this.setState({ user_id: e.value })
            this.setState({ userReset: e })
        }
    }

    handleChangeRepo = (e) => {
        if (this.state.secStorage.role === 'user')
            if (e.label.length > 0 & this.state.access_level.length > 0) this.setState({ invalid: false }); else this.setState({ invalid: true });
        else
            if (this.state.user_name.length > 0 & e.label.length > 0 & this.state.access_level.length > 0) this.setState({ invalid: false }); else this.setState({ invalid: true });
        this.setState({ repo: e.label })
        this.setState({ repo_id: e.value })
        this.setState({ repoReset: e })
    }

    handleChangeAccessLevel = (e) => {
        if (this.state.secStorage.role === 'user')
            if (this.state.repo.length > 0 & e.label.length > 0) this.setState({ invalid: false }); else this.setState({ invalid: true });
        else
            if (this.state.user_name.length > 0 & this.state.repo.length > 0 & e.label.length > 0) this.setState({ invalid: false }); else this.setState({ invalid: true });
        this.setState({ access_level: e.label })
        this.setState({ access_level_id: e.value })
        this.setState({ accLevelReset: e })
    }

    /* onChangeModify(e) {
        if (this.state.modify === "unmodified") { this.setState({ modify: e.target.value }); alert("Please select this option only if you are upgrading your access level for the selected project/group"); } else this.setState({ modify: 'unmodified' });
        this.setState({ checked: !this.state.checked })

    } */

    submitHandler = (e) => {
        var modifyCheck;
        if (this.state.modify === "modify") modifyCheck = 1; else modifyCheck = 0;
        var userName;
        if (this.state.secStorage.role === 'user') {
            userName = this.state.userDataList.gitlabId;
        }
        else {
            userName = this.state.user_name;
        }
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var today = new Date()
        var axios = require('axios');
        var FormData = require('form-data');
        var data = new FormData();
        let mon = today.getMonth() + 1
        data.append('username', userName);
        data.append('description', this.state.category);
        data.append('resource_id', this.state.repo_id);
        data.append('resource', this.state.repo);
        data.append('acc_level_id', this.state.access_level_id);
        data.append('acc_level', this.state.access_level);
        data.append('status', 'Pending');
        data.append('approvedBy', 'Pending');
        data.append('modify', modifyCheck);
        data.append('timestamp', today.getDate() + "-" + mon + "-" + today.getFullYear() + ":" + today.getHours() + ":" + today.getMinutes());

        var config = {
            method: 'post',
            url: baseUrl + '/gitrequest',
            headers: {
                'Authorization': 'Basic ' + base64,
                'content-type': 'multipart/form-data'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });
        this.setState({ userReset: -1 })
        this.setState({ categoryReset: -1 })
        this.setState({ repoReset: -1 })
        this.setState({ accLevelReset: -1 })
        this.setState({ invalid: true })
        this.setState({ modify: 'unmodified' })
        this.setState({ checked: false })
    }

    render() {
        var gitInput;
        if (this.state.secStorage.role === 'user') {
            gitInput = <Input defaultValue={this.state.userDataList.gitlabId} readOnly style={{ fontSize: 16 }} type="email" />;
        }
        else
            gitInput = <Select value={this.state.userReset} placeholder="Select Username" isSearchable options={this.state.users} id="user_name" onChange={(e) => this.handleChangeUser(e)} />
        return (
            <div style={{ marginLeft: 20 }}>
                <Form id="gitrequestfrom">
                    <Table style={{ width: "30%", marginLeft: 400, marginTop: "10%", fontSize: 16 }}>
                        <tbody >
                            <tr >
                                <td> {gitInput}
                                    {/*  {this.state.user_name <= 0 &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>Select user</span>} */}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Select value={this.state.categoryReset} placeholder="Project or Group Access?" onChange={(e) => this.handleChange(e)} options={option} id="category" />
                                    {/*  {this.state.category.length <= 0 &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>Select category</span>} */}
                                </td>
                            </tr>
                            <tr>
                                <td><Select isLoading={this.state.checkLoading} value={this.state.repoReset} placeholder="Select Project/Group name" isSearchable options={this.state.list} id="repo" onChange={(e) => this.handleChangeRepo(e)} />
                                    {/*  {this.state.repo.length <= 0 &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>Select {this.state.category}</span>} */}
                                </td>
                            </tr>
                            <tr>
                                <td><Select value={this.state.accLevelReset} placeholder="Select Access Level" options={accessLevels} onChange={(e) => this.handleChangeAccessLevel(e)} id="access_level" />
                                    {/* {this.state.access_level.length <= 0 &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>Select Access Level</span>} */}
                                </td>
                            </tr>
                            <tr>
                                <td><Button disabled={this.state.invalid} onClick={this.submitHandler} style={{ fontSize: 16, marginLeft: 130, backgroundColor: "#33382e", color: "white" }} >Submit</Button></td>
                            </tr>
                        </tbody>
                    </Table>

                </Form>
            </div>
        );
    }
}
export default GitRequest;