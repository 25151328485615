import React from 'react';
import ReactDOM from 'react-dom';
import Home from './components/Home'


if (process.env.REACT_APP_SPRING_ENDPOINT == "traum-devops.svc.tfw.io")
    console.log = function () { };

ReactDOM.render(
    <Home />
    , document.getElementById('sample'));



