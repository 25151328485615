import './index.css';
import * as React from 'react';
import { SidebarComponent } from '@syncfusion/ej2-react-navigations';
import { MenuComponent } from '@syncfusion/ej2-react-navigations';
import { SampleBase } from './sample-base';
import './components/Pag.css'
import OptionSelect from './components/OptionSelect'
import ViewAnnouncement from './components/ViewAnnouncement'
import Profile from './components/Profile'
import SecureStorage from 'secure-web-storage';

export default class SidebarWithMenu extends SampleBase {
  constructor() {
    super(...arguments);
    this.mediaQuery = '(min-width: 600px)';
    this.menuItemsAdmin = [
      {

        text: 'Announcement',
        iconCss: 'icon-bell-alt icon',
        items: [
          { text: 'View Announcement' },
          { text: 'Add Announcement' },
        ]
      },
      {
        text: 'Create Access Request',
        iconCss: 'fas fa-plus',
        items: [
          { text: 'Gitlab Repositories' },
          { text: 'Kubernetes Namespace' },
        ]
      },
      {
        text: 'Create Infra Request',
        iconCss: 'fas fa-plus',
        items: [
          { text: 'GCS Bucket' },
          /* { text: 'Cloud SQL Instance Database' },
          { text: 'Cloud SQL Instance User' }, */
        ]
      },
      {
        text: 'Create Gitlab Resource',
        iconCss: 'fas fa-plus',
        items: [
          { text: 'Group' },
          { text: 'Project' },
        ]
      },
      {
        text: 'Create Upscale Request',
        iconCss: 'fas fa-plus',
        items: [
          { text: 'Upscale' },
          // { text: 'Project' },
        ]
      },
      {
        text: 'Revoke Access',
        iconCss: 'fas fa-trash',
        items: [
          { text: 'Revoke K8s Namespace Access' },
        ]
      },
      {
        text: 'Personal Request History',
        iconCss: 'fas fa-list',
        items: [
          { text: 'Personal Gitlab Access History' },
          { text: 'Personal Gitlab Create History' },
          { text: 'Personal GCS Bucket Create History' },
          { text: 'Personal K8s Namespace Access History' },
          { text: 'Personal K8s Upscale Request History' },
        ]
      },
      {
        text: 'Approve Pending Request',
        iconCss: 'fas fa-list',
        items: [
          { text: 'Approve Gitlab Access Request' },
          { text: 'Approve Gitlab Create Request' },
          { text: 'Approve GCS Bucket Create Request' },
          { text: 'Approve K8s Namespace Access Request' },
          { text: 'Approve K8s Upscale  Request' },
        ]
      },
      {
        text: 'All Requests',
        iconCss: 'fas fa-list',
        items: [
          { text: 'All Gitlab Access Request' },
          { text: 'All Gitlab Create Request' },
          { text: 'All K8s Namespace Access Request' },
          { text: 'All K8s Upscale Request' },
          { text: 'All GCS Bucket Create Request' },
          { text: 'All K8s Revoke History' },
        ]
      },
      {
        text: 'Users',
        iconCss: 'icon-user icon',
        items: [
          { text: 'View Users' },
          { text: 'Update Users' },
        ]
      }
    ];
    this.AccountMenuItemAdmin = [
      {
        text: 'Account',
        items: [
          { text: 'Profile' },
          { text: 'Sign out' },
        ]
      }

    ];
    this.menuItemsUser = [
      {

        text: 'Announcement',
        iconCss: 'icon-bell-alt icon',
        items: [
          { text: 'View Announcement' },
        ]
      },
      {
        text: 'Create Access Request',
        iconCss: 'fas fa-plus',
        items: [
          { text: 'Gitlab Repositories' },
          { text: 'Kubernetes Namespace' },
        ]
      },
      {
        text: 'Create Infra Request',
        iconCss: 'fas fa-plus',
        items: [
          { text: 'GCS Bucket' },
          /* { text: 'Cloud SQL Instance Database' },
          { text: 'Cloud SQL Instance User' }, */
        ]
      },
      {
        text: 'Create Gitlab Resource',
        iconCss: 'fas fa-plus',
        items: [
          { text: 'Group' },
          { text: 'Project' },
        ]
      },
      {
        text: 'Create Upscale Request',
        iconCss: 'fas fa-plus',
        items: [
          { text: 'Upscale' },
          // { text: 'Project' },
        ]
      },
      {
        text: 'Personal Request History',
        iconCss: 'fas fa-list',
        items: [
          { text: 'Personal Gitlab Access History' },
          { text: 'Personal Gitlab Create History' },
          { text: 'Personal GCS Bucket Create History' },
          { text: 'Personal K8s Namespace Access History' },
          { text: 'Personal K8s Upscale Request History' },
        ]
      },
      {
        text: 'View Admins',
        iconCss: 'icon-user icon',
      }
    ];
    this.AccountMenuItemUser = [

      {
        text: 'Account',
        items: [
          { text: 'Profile' },
          { text: 'Sign out' },
        ]
      }

    ];
    this.enableDock = true;
    this.dockSize = '52px';
    this.width = '220px';
    this.target = '.main-content';
  }
  render() {
    var CryptoJS = require("crypto-js");
    var SECRET_KEY = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY;
    var secureStorage = new SecureStorage(localStorage, {
      hash: function hash(key) {
        key = CryptoJS.SHA256(key, SECRET_KEY);
        return key.toString();
      },
      encrypt: function encrypt(data) {
        data = CryptoJS.AES.encrypt(data, SECRET_KEY);
        data = data.toString();
        return data;
      },
      decrypt: function decrypt(data) {
        data = CryptoJS.AES.decrypt(data, SECRET_KEY);
        data = data.toString(CryptoJS.enc.Utf8);
        return data;
      }
    });

    var roleSpecificMenuItem = [];
    var roleSpecificAccountMenuItem = [];
    if (secureStorage.getItem('UserInfo').role === 'admin') {
      roleSpecificAccountMenuItem = this.AccountMenuItemAdmin;
      roleSpecificMenuItem = this.menuItemsAdmin
    }
    else {
      roleSpecificAccountMenuItem = this.AccountMenuItemUser;
      roleSpecificMenuItem = this.menuItemsUser
    }
    var prevElem = '';
    var prevtoprevElem = '';
    var firstPage;
    var isFirstPage = secureStorage.getItem('UserInfo').firstLogin
    if (isFirstPage === true) { firstPage = <Profile />; } else firstPage = <ViewAnnouncement />;
    // if ((!secureStorage.getItem('UserInfo').gitlabId) && secureStorage.getItem('UserInfo').firstLogin != true)
    //   setTimeout(function () { window.location.href = '/logout' }, 100);
    return (
      <div className="control-section sidebar-menu">
        <div className="col-lg-12 col-sm-12 col-md-12 center">
          <a className="e-btn" id="newTab" target="_blank" onClick={this.newTabClick.bind(this)}>Open in new Tab</a>
        </div>
        <div id="wrapper">
          <div className="col-lg-12 col-sm-12 col-md-12">
            <div className="header-section dock-menu" id="header">
              <ul className="header-list">
                <li id="hamburger" className="icon-menu icon list" onClick={this.openClick.bind(this)}></li>
                <li className="right-header list">
                  <div className="horizontal-menu">
                    <MenuComponent showItemOnClick items={roleSpecificAccountMenuItem} select={e => { if (prevElem) { if (prevtoprevElem) prevtoprevElem.style.setProperty("background-color", "initial"); prevtoprevElem = prevElem } e.element.style.setProperty("background-color", "black", "important"); prevElem = e.element; OptionSelect(e.element.innerText) }} cssClass='dock-menu'  ></MenuComponent>
                  </div>
                </li>
                <li style={{ marginLeft: "45%", fontSize: 30, listStyle: "none" }}>Traum Devops</li>
              </ul>
            </div>
            <SidebarComponent id="sidebar-menu" ref={Sidebar => this.sidebarobj = Sidebar} enableDock={this.enableDock} mediaQuery={this.mediaQuery} dockSize={this.dockSize} width={this.width} target={this.target}>
              <div className="main-menu">
                <MenuComponent showItemOnClick enableScrolling id='mymenu' items={roleSpecificMenuItem} orientation='Vertical' select={e => {
                  e.element.style.setProperty("background-color", "black", "important");
                  if (e.element.innerText === "View Announcement" || e.element.innerText === "Add Announcement" || e.element.innerText === "Gitlab Repositories" || e.element.innerText === "Kubernetes Namespace" || e.element.innerText === "Personal Gitlab Access History" || e.element.innerText === "Project" || e.element.innerText === "Group" || e.element.innerText === "Personal K8s Namespace Access History" || e.element.innerText === "Personal Gitlab Create History" || e.element.innerText === "Approve K8s Namespace Access Request" || e.element.innerText === "Approve Admin Request" || e.element.innerText === "Approve Gitlab Access Request" || e.element.innerText === "Approve Gitlab Create Request" || e.element.innerText === "View Users" || e.element.innerText === "Update Users" || e.element.innerText === "All Gitlab Access Request" || e.element.innerText === "All Gitlab Create Request" || e.element.innerText === "All K8s Namespace Access Request" || e.element.innerText === "All K8s Revoke History" || e.element.innerText === "Revoke K8s Namespace Access" || e.element.innerText === "Profile" || e.element.innerText === "Sign out" || e.element.innerText === "Personal GCS Bucket Create History" || e.element.innerText === "Approve GCS Bucket Create Request" || e.element.innerText === "All GCS Bucket Create Request" || e.element.innerText === "GCS Bucket" || e.element.innerText === "Cloud SQL Instance Database" || e.element.innerText === "Cloud SQL Instance User" || e.element.innerText === "Upscale" || e.element.innerText === "Personal K8s Upscale Request History" || e.element.innerText === "Approve K8s Upscale  Request" || e.element.innerText === "All K8s Upscale Request") {
                    if (prevElem) {
                      if (prevtoprevElem) prevtoprevElem.style.setProperty("background-color", "initial");
                    }
                  }
                  else {
                    if (prevElem) prevElem.style.setProperty("background-color", "initial");
                    if (prevElem) {
                      if (prevtoprevElem) prevtoprevElem.style.setProperty("background-color", "initial");
                    }
                  }
                  if (prevElem) {
                    if (prevElem === e.element) e.element.style.setProperty("background-color", "black", "important");
                    if (prevtoprevElem) {
                      if (prevtoprevElem === e.element) e.element.style.setProperty("background-color", "black", "important");
                    }
                    prevtoprevElem = prevElem;
                  }
                  prevElem = e.element;
                  OptionSelect(e.element.innerText)
                }
                } cssClass='dock-menu' style={{ marginTop: 40 }}>
                </MenuComponent>
              </div>
            </SidebarComponent>
            <div className="main-content" id="maintext" >
              <div id="content" style={{ maxHeight: "85", height: "84%", overflowX: "scroll", overflowY: "scroll" }} >
                {firstPage}
              </div>
              <div id="footer" style={{ marginLeft: "220px", marginTop: 1, position: "relative", display: "flex", justifyContent: "center" }} >

              </div>
            </div>
          </div>
        </div>
      </div >);

  }
  //open newTab
  newTabClick() {
    let URL = window.location.href.replace(window.location.search, '');
    document.getElementById('newTab').setAttribute('href', URL.split('#')[0] + 'sidebar/sidebar-menu/index.html');
  }
  //open the sidebar
  openClick() {
    this.sidebarobj.toggle();
    if (document.getElementById("footer").style.marginLeft == "220px")
      document.getElementById("footer").style.marginLeft = "52px"
    else
      document.getElementById("footer").style.marginLeft = "220px"
  }
}

