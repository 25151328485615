import React, { Component } from 'react'
import { Button, Form, Input, Table, } from 'reactstrap';
import Select from 'react-select'
import SecureStorage from 'secure-web-storage';

const opt = [
    { value: 'prod', label: 'Production' },
    { value: 'stg', label: 'Staging' },
    { value: 'agitha', label: 'Agitha' }
]

export default class RevokeKubernetesNamespaceAccess extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            namespace: '',
            env: '',
            envName: '',
            invalid: true,
            checkEmail: false,
            clusterReset: -1,
            showCluster: false,
            showNamespace: false,
            showEmail: false,
            secStorage: '',
        }
        this.handleChangeEmail = this.handleChangeEmail.bind(this)
        this.handleChangeNamespace = this.handleChangeNamespace.bind(this)
        this.handleChangeEnv = this.handleChangeEnv.bind(this)
    }

    componentDidMount() {
        var CryptoJS = require("crypto-js");
        var SECRET_KEY = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY;
        var secureStorage = new SecureStorage(localStorage, {
            hash: function hash(key) {
                key = CryptoJS.SHA256(key, SECRET_KEY);
                return key.toString();
            },
            encrypt: function encrypt(data) {
                data = CryptoJS.AES.encrypt(data, SECRET_KEY);
                data = data.toString();
                return data;
            },
            decrypt: function decrypt(data) {
                data = CryptoJS.AES.decrypt(data, SECRET_KEY);
                data = data.toString(CryptoJS.enc.Utf8);
                return data;
            }
        });
        this.setState({ secStorage: secureStorage.getItem('UserInfo') })

    }

    handleChangeEmail = (e) => {
        this.setState({ showCluster: true })
        this.setState({ showNamespace: true })
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        var checkEmail = expression.test(String(e.target.value).toLowerCase())
        if (checkEmail === true) this.setState({ checkEmail: true }); else this.setState({ checkEmail: false })
        this.setState({ email: e.target.value })
        if (checkEmail === true & this.state.namespace.length > 0 & e.target.value.length > 0 & this.state.env.length > 0) this.setState({ invalid: false }); else { this.setState({ invalid: true }); this.setState({ showEmail: true }) }
    }

    handleChangeNamespace = (e) => {
        if (this.state.secStorage.role === 'user') {
            this.setState({ showCluster: true })
            if (e.target.value.length > 0 & this.state.env.length > 0) this.setState({ invalid: false }); else { this.setState({ invalid: true }); this.setState({ showNamespace: true }) }
            this.setState({ namespace: e.target.value })
        }
        else {
            this.setState({ showEmail: true })
            this.setState({ showCluster: true })
            if (this.state.checkEmail === true & this.state.email.length > 0 & e.target.value.length > 0 & this.state.env.length > 0) this.setState({ invalid: false }); else { this.setState({ invalid: true }); this.setState({ showNamespace: true }) }
            this.setState({ namespace: e.target.value })
        }


    }

    handleChangeEnv = (e) => {
        if (this.state.secStorage.role === 'user') {
            if (e.value.length > 0 & this.state.namespace.length) this.setState({ invalid: false }); else this.setState({ invalid: true });
            this.setState({ env: e.value })
            this.setState({ envName: e.label })
            this.setState({ clusterReset: e })
        }
        else {
            if (this.state.checkEmail === true & this.state.namespace.length > 0 & e.value.length > 0 & this.state.email.length > 0) this.setState({ invalid: false }); else this.setState({ invalid: true });
            this.setState({ env: e.value })
            this.setState({ envName: e.label })
            this.setState({ clusterReset: e })
        }
    }

    submitHandler = (e) => {
        var userName;
        userName = this.state.email;
        var r = window.confirm("Are you sure you want to revoke access of " + userName + " for namespace " + this.state.namespace + "(" + this.state.envName + ")");
        if (r === true) {
            var springCreds = process.env.REACT_APP_SPRING_CREDS;
            var base64 = btoa(springCreds);
            var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
            var today = new Date()
            var axios = require('axios');
            var FormData = require('form-data');
            var data = new FormData();
            data.append('username', userName);
            data.append('description', this.state.envName);
            data.append('resource', this.state.namespace);
            data.append('acc_level', 'Full Access');
            data.append('approvedBy', this.state.secStorage.email);
            data.append('status', 'Pending');
            data.append('timestamp', today.getDate() + "-" + today.getMonth() + "-" + today.getFullYear() + ":" + today.getHours() + ":" + today.getMinutes());

            var config = {
                method: 'post',
                url: baseUrl + '/k8srevoke',
                headers: {
                    'Authorization': 'Basic ' + base64,
                    'content-type': 'multipart/form-data'
                },
                data: data
            };
            axios(config)
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                })
                .catch(function (error) {
                    alert(error)
                    console.log(error);
                });
            this.setState({ clusterReset: -1 })
            document.getElementById("form2").reset();
            this.setState({ invalid: true });
            this.setState({ showCluster: false })
        }

    }

    render() {
        return (
            <div style={{ marginLeft: 20 }}>
                <Form id="form2">
                    <Table style={{ fontSize: 16, width: "30%", marginLeft: 400, marginTop: "18%", }}>
                        <tbody >
                            <tr>
                                <td>
                                    <Select value={this.state.clusterReset} placeholder="Select Cluster" id="category" options={opt} onChange={(e) => this.handleChangeEnv(e)} />
                                    {this.state.envName.length <= 0 && this.state.showCluster &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>Select user</span>}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Input style={{ fontSize: 16 }} type="text" placeholder="Enter namespace" onChange={(e) => this.handleChangeNamespace(e)} />
                                    {this.state.namespace.length <= 0 && this.state.showNamespace &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>Group name cannot be empty</span>}
                                </td>
                            </tr>
                            <tr>
                                <td><Input style={{ fontSize: 16 }} type="email" required placeholder="Enter email id" onChange={(e) => this.handleChangeEmail(e)} />
                                    {this.state.email.length <= 0 && this.state.showEmail &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>Group name cannot be empty</span>}

                                    {this.state.checkEmail === false && this.state.showEmail &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>&nbsp;&nbsp;Invalid Email</span>}
                                </td>
                            </tr>
                            <tr>
                                <td><Button disabled={this.state.invalid} style={{ fontSize: 16, marginLeft: 130, backgroundColor: "#33382e", color: "white" }} onClick={this.submitHandler} >Submit</Button></td>
                            </tr>
                        </tbody>
                    </Table>

                </Form>
            </div>
        )
    }
}