import React, { Component } from 'react';
import { Button, Form, Input, Table, Label } from 'reactstrap';
import SecureStorage from 'secure-web-storage';

export default class AddAnnouncement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: '',
            invalid: true,
            showTitle: false,
            showDescription: false,
            priority: 'seagreen',
            secStorage: '',
        }
        this.submitHandler = this.submitHandler.bind(this)
        this.changeHandlerTitle = this.changeHandlerTitle.bind(this)
        this.changeHandlerDescription = this.changeHandlerDescription.bind(this)
        this.optionValueTitle = this.optionValueTitle.bind(this)
        this.optionValueDescription = this.optionValueDescription.bind(this)
        this.onChangePriority = this.onChangePriority.bind(this)
    }

    componentDidMount() {
        var CryptoJS = require("crypto-js");
        var SECRET_KEY = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY;
        var secureStorage = new SecureStorage(localStorage, {
            hash: function hash(key) {
                key = CryptoJS.SHA256(key, SECRET_KEY);
                return key.toString();
            },
            encrypt: function encrypt(data) {
                data = CryptoJS.AES.encrypt(data, SECRET_KEY);
                data = data.toString();
                return data;
            },
            decrypt: function decrypt(data) {
                data = CryptoJS.AES.decrypt(data, SECRET_KEY);
                data = data.toString(CryptoJS.enc.Utf8);
                return data;
            }
        });
        this.setState({ secStorage: secureStorage.getItem('UserInfo') })
    }

    changeHandlerTitle = (e) => {
        if (e.target.value.length > 0 & this.state.description.length > 0) this.setState({ invalid: false }); else { this.setState({ invalid: true }); this.setState({ showTitle: true }) }
        this.optionValueTitle(e.target.value)
    }

    changeHandlerDescription = (e) => {
        if (e.target.value.length > 0 & this.state.title.length > 0) this.setState({ invalid: false }); else { this.setState({ invalid: true }); this.setState({ showDescription: true }) }
        this.optionValueDescription(e.target.value)
    }

    optionValueTitle(props) {
        this.setState({ title: props })
    }

    optionValueDescription(props) {
        this.setState({ description: props })
    }

    onChangePriority(e) {
        this.setState({ priority: e.target.value })
    }

    submitHandler = (e) => {
        var today = new Date()
        var axios = require('axios');
        var FormData = require('form-data');
        var data = new FormData();
        data.append('title', this.state.title);
        data.append('user', this.state.secStorage.email);
        data.append('description', this.state.description);
        data.append('priority', this.state.priority);
        data.append('timestamp', today.getDate() + "-" + today.getMonth() + "-" + today.getFullYear() + ":" + today.getHours() + ":" + today.getMinutes());
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var config = {
            method: 'post',
            url: baseUrl + '/announcement',
            headers: {
                'Authorization': 'Basic ' + base64,
                'content-type': 'multipart/form-data'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });
        document.getElementById("form3").reset();
        this.setState({ invalid: true })
    }

    render() {
        return (
            <div style={{ marginLeft: 20, marginTop: 20 }}>
                <Form id="form3">
                    <Table style={{}}>
                        <tbody >
                            <tr>
                                <td><Input onChange={(e) => this.changeHandlerTitle(e)} type="text" placeholder="Enter Title" style={{ width: "50%", fontSize: 20 }} />
                                    {this.state.title.length <= 0 && this.state.showTitle &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>Title cannot be empty</span>}
                                </td>
                            </tr>
                            <tr style={{}} >
                                <td ><textarea onChange={(e) => this.changeHandlerDescription(e)} rows="5" cols="20" placeholder="Enter Description" style={{ width: "50%", fontSize: 15 }} />

                                </td>
                            </tr>
                            {this.state.description.length <= 0 && this.state.showDescription &&
                                <span style={{ fontSize: 9, color: "red", float: "left", marginLeft: 10 }} className='error'>Description cannot be empty</span>}
                            <tr>
                                <div onChange={this.onChangePriority}>
                                    <div style={{ display: "inline", marginLeft: 10 }}><Input style={{}} defaultChecked type="radio" name="radio1" value="seagreen" /> <Label style={{ marginLeft: 15, marginTop: 3 }}>P2</Label></div>

                                    <div style={{ display: "inline", marginLeft: 245 }}><Input style={{}} type="radio" name="radio1" value="gold" /><Label style={{ marginLeft: 15, marginTop: 3 }}>P1</Label></div>

                                    <div style={{ display: "inline", marginLeft: 260 }}><Input style={{}} type="radio" name="radio1" value="red" /><Label style={{ marginLeft: 15, marginTop: 3 }}>P0</Label></div>
                                </div>
                            </tr>
                            <tr>
                                <td><Button disabled={this.state.invalid} onClick={this.submitHandler} style={{ fontSize: 16, backgroundColor: "#33382e", color: "white", width: "50%" }} >Submit</Button></td>
                            </tr>
                        </tbody>
                    </Table>
                </Form>
            </div >
        )
    }
}