import '../index.css';
import React, { Component } from 'react';
import { Table, } from 'reactstrap';

class AllRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            val: "",
            page: '',
            noOfEntries: '',
        }
        this.optionValue = this.optionValue.bind(this)
        this.showReason = this.showReason.bind(this)
    }

    componentWillReceiveProps(props) {
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var currentThis = this;
        this.setState({ list: [] })
        var axios = require('axios');
        var config = {
            method: 'get',
            url: baseUrl + '/gitrequest?page=' + props.page + '&noOfEntries=' + props.numberOfEntries + '&filter=all',
            headers: {
                'Authorization': 'Basic ' + base64,
                'content-type': 'multipart/form-data'
            },
        };
        axios(config)
            .then(function (response) {
                for (var i = 0; i < response.data.content.length; i++) {
                    currentThis.state.list = [...currentThis.state.list, response.data.content[i]]
                }
                currentThis.optionValue(currentThis.state.list)
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });
    }


    componentDidMount() {
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var currentThis = this;
        var axios = require('axios');
        var config = {
            method: 'get',
            url: baseUrl + '/gitrequest?page=' + this.props.page + '&noOfEntries=' + this.props.numberOfEntries + '&filter=all',
            headers: {
                'Authorization': 'Basic ' + base64,
                'content-type': 'multipart/form-data'
            },
        };
        axios(config)
            .then(function (response) {
                for (var i = 0; i < response.data.content.length; i++) {
                    currentThis.state.list = [...currentThis.state.list, response.data.content[i]]
                }
                currentThis.optionValue(currentThis.state.list)
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });
    }

    optionValue(list) {
        this.setState({ list: list })
    }

    showReason(status, failReason) {
        if (status === "Completed")
            alert(status)
        else if (status === "Failed" || status === "Rejected")
            alert(failReason)
    }

    renderTableData() {
        var i = 0;
        return this.state.list.map((request) => {
            const { id, username, description, resource, acc_level, status, timestamp, approvedBy, failReason } = request
            if (status === "Approved" || status === "Completed") this.state.val = "green"; else if (status === "Rejected" || status === "Failed") this.state.val = "red"; else this.state.val = "black";
            var bgcolor;
            if (i % 2 === 0) bgcolor = "tan"; else bgcolor = "papayawhip";
            i = i + 1;
            return (
                <tr key={id} style={{ fontSize: 14, backgroundColor: bgcolor, opacity: "75%" }}>
                    <td>{username}</td>
                    <td>{description}</td>
                    <td>{resource}</td>
                    <td>{acc_level}</td>
                    <td onClick={() => this.showReason(status, failReason)} style={{ color: this.state.val, cursor: "pointer" }}><b>{status}</b></td>
                    <td>{approvedBy}</td>
                    <td>{timestamp}</td>
                </tr>
            )
        })
    }

    render() {
        return (
            <div>
                <Table style={{ marginTop: 20, }} >
                    <tbody>
                        <tr style={{ fontSize: 18, backgroundColor: "peru" }} >
                            <th>Username</th>
                            <th>Type</th>
                            <th>Resource</th>
                            <th>Access Level</th>
                            <th>Status</th>
                            <th>Reviewer</th>
                            <th>Timestamp</th>
                        </tr>
                        {this.renderTableData()}
                    </tbody>
                </Table>
            </div>

        );
    }
}

export default AllRequest;