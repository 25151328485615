import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import App from '../App'
import Login from './Login'
import Logout from './Logout'
import PrivateRoute from './PrivateRoute'
import LogoutPrivateRoute from './LogoutPrivateRoute'

const Home = () => {
    return (
        <Router >
            <PrivateRoute exact path='/' component={App} />
            <LogoutPrivateRoute exact path='/logout' component={Logout} />
            <Switch>
                <Route exact path='/login' component={Login} />
            </Switch>
        </Router>
    )
}

export default Home
