import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Toast, ToastBody, ToastHeader, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import $ from 'jquery';
import SecureStorage from 'secure-web-storage';

export default class ViewAnnouncement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            btnLabel: '',
            modal: false,
            sel: 0
        }
        this.toggle = this.toggle.bind(this)
        this.renderData = this.renderData.bind(this)
        this.optionValue = this.optionValue.bind(this)
    }

    toggle = (e) => {
        if (this.state.modal === false) {
            this.setState({ sel: e })
        }
        else
            this.setState({ modal: false })
    }

    componentDidMount() {
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var currentThis = this;
        var axios = require('axios');
        var config = {
            method: 'get',
            timeout: 3000,
            url: baseUrl + '/announcement',
            headers: {
                'Authorization': 'Basic ' + base64,
            }
        };
        axios(config)
            .then(function (response) {
                currentThis.setState({ list: response.data })
                currentThis.optionValue(currentThis.state.list)
            })
            .catch(function (error) {
                //console.log(error.message);
                if (error.message === "timeout of 3000ms exceeded") alert("Please connect L2TP VPN!")
                else alert(error)
            });
    }

    optionValue(list) {
        this.setState({ list: list })
    }

    renderData() {
        return this.state.list.map((request) => {
            const { id, title, description, timestamp, user, priority } = request
            var desc = description.replace("\n", "")
            var bool = false;
            if (this.state.sel == id) bool = true;
            return (
                <div key={id}>
                    <Button id={id} value={id} style={{ opacity: "100%", borderRadius: 15, fontSize: 14, width: "20%", color: "black", backgroundColor: priority }} onClick={(e) => this.toggle(id)}><b>Click to Open</b></Button>
                    <Toast id={id} style={{ maxWidth: "80%" }} isOpen={bool}>
                        <ToastHeader style={{ fontSize: 15, color: "black", borderRadius: 15, backgroundColor: priority, opacity: "65%" }} toggle={this.toggle}>{title}</ToastHeader>
                        <ToastBody style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", fontSize: 15, color: "black", borderRadius: 15, backgroundColor: priority, opacity: "100%" }}>
                            {desc}
                            <br />
                            <br />
                            <h5 style={{ textAlign: "right" }}>
                                <br />
                                <i>{user}:     {timestamp}</i></h5>
                        </ToastBody>
                    </Toast>
                    <br /><br />
                </div >
            )
        }
        )
    }

    render() {
        return (
            <div style={{ marginLeft: 20, marginTop: 20 }}>
                {this.renderData()}
            </div>
        )
    }
}