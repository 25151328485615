import '../index.css';
import React, { Component } from 'react';
import { Button, Table, } from 'reactstrap';
import { FcApprove, FcDisapprove } from 'react-icons/fc';
import SecureStorage from 'secure-web-storage';

class ApprovePendingGitlabRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            page: '',
            noOfEntries: '',
            secStorage: '',
        }
        this.optionValue = this.optionValue.bind(this)
        this.approve = this.approve.bind(this)
        this.reject = this.reject.bind(this)
    }

    componentWillReceiveProps(props) {
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var currentThis = this;
        this.setState({ list: [] })
        var axios = require('axios');
        var config = {
            method: 'get',
            url: baseUrl + '/gitlabcreateresource?page=' + props.page + '&noOfEntries=' + props.numberOfEntries + '&filter=pending&user=' + this.state.secStorage.email,
            headers: {
                'Authorization': 'Basic ' + base64,
            }
        };
        axios(config)
            .then(function (response) {
                currentThis.optionValue(response.data.content)
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });
    }

    componentDidMount() {
        var CryptoJS = require("crypto-js");
        var SECRET_KEY = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY;
        var secureStorage = new SecureStorage(localStorage, {
            hash: function hash(key) {
                key = CryptoJS.SHA256(key, SECRET_KEY);
                return key.toString();
            },
            encrypt: function encrypt(data) {
                data = CryptoJS.AES.encrypt(data, SECRET_KEY);
                data = data.toString();
                return data;
            },
            decrypt: function decrypt(data) {
                data = CryptoJS.AES.decrypt(data, SECRET_KEY);
                data = data.toString(CryptoJS.enc.Utf8);
                return data;
            }
        });
        this.setState({ secStorage: secureStorage.getItem('UserInfo') })

        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var currentThis = this;
        var axios = require('axios');
        var config = {
            method: 'get',
            url: baseUrl + '/gitlabcreateresource?page=' + this.props.page + '&noOfEntries=' + this.props.numberOfEntries + '&filter=pending&user=' + secureStorage.getItem('UserInfo').email,
            headers: {
                'Authorization': 'Basic ' + base64,
            }
        };
        axios(config)
            .then(function (response) {
                currentThis.optionValue(response.data.content)
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });
    }

    optionValue(list) {
        this.setState({ list: list })
    }



    approve(id, user, group_id, category, type, resource, resource_id, visibility, status, timestamp) {
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var axios2 = require('axios');
        var FormData2 = require('form-data');
        var data1 = new FormData2();
        data1.append('id', JSON.parse(JSON.stringify(id)).id);
        data1.append('user', JSON.parse(JSON.stringify(user)).user);
        data1.append('type', JSON.parse(JSON.stringify(type)).type);
        data1.append('resource', JSON.parse(JSON.stringify(resource)).resource);
        data1.append('resource_id', JSON.parse(JSON.stringify(resource_id)).resource_id);
        data1.append('category', JSON.parse(JSON.stringify(category)).category);
        data1.append('group_id', JSON.parse(JSON.stringify(group_id)).group_id);
        data1.append('visibility', JSON.parse(JSON.stringify(visibility)).visibility);
        data1.append('approvedBy', this.state.secStorage.email.split('@')[0]);
        data1.append('status', "Approved");
        data1.append('failReason', "Approved");
        data1.append('timestamp', JSON.parse(JSON.stringify(timestamp)).timestamp);

        var config2 = {
            method: 'put',
            url: baseUrl + '/gitlabcreateresource',
            headers: {
                'Authorization': 'Basic ' + base64,
                'content-type': 'multipart/form-data'
            },
            data: data1
        };
        axios2(config2)
            .then(function (response) {
            })
            .catch(function (error) {
                alert(error)
                //console.log(error);
            });

        this.setState(prevState => {
            var users = [...prevState.list];
            users.map(item => {
                if (item.id === JSON.parse(JSON.stringify(id)).id) {
                    item.status = "Approved"
                }
            })
            const temp_users = users.filter(item => item.id !== JSON.parse(JSON.stringify(id)).id)
            return {
                list: temp_users
            }
        })
    }

    reject(id, user, group_id, category, type, resource, resource_id, visibility, status, timestamp) {
        var reason = prompt("Please mention reason for rejection", "Rejected");
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var axios = require('axios');
        var FormData = require('form-data');
        var data = new FormData();
        data.append('id', JSON.parse(JSON.stringify(id)).id);
        data.append('user', JSON.parse(JSON.stringify(user)).user);
        data.append('type', JSON.parse(JSON.stringify(type)).type);
        data.append('resource', JSON.parse(JSON.stringify(resource)).resource);
        data.append('resource_id', "NA");
        data.append('visibility', JSON.parse(JSON.stringify(visibility)).visibility);
        data.append('category', JSON.parse(JSON.stringify(category)).category);
        data.append('group_id', JSON.parse(JSON.stringify(group_id)).group_id);
        data.append('approvedBy', this.state.secStorage.email.split('@')[0]);
        data.append('failReason', reason)
        data.append('status', 'Rejected');
        data.append('timestamp', JSON.parse(JSON.stringify(timestamp)).timestamp);

        var config = {
            method: 'put',
            url: baseUrl + '/gitlabcreateresource',
            timeout: 3000,
            headers: {
                'Authorization': 'Basic ' + base64,
                'content-type': 'multipart/form-data'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
            })
            .catch(function (error) {
                alert(error)
            });
        this.setState(prevState => {
            var users = [...prevState.list];
            users.map(item => {
                if (item.id === JSON.parse(JSON.stringify(id)).id) {
                    item.status = "Rejected"
                }
            })
            const temp_users = users.filter(item => item.id !== JSON.parse(JSON.stringify(id)).id)
            return {
                list: temp_users
            }
        })
    }

    renderTableData() {
        var i = 0;
        return this.state.list.map((request) => {
            const { id, user, type, category, group_id, resource, resource_id, visibility, status, timestamp } = request
            var bgcolor;
            if (i % 2 === 0) bgcolor = "tan"; else bgcolor = "papayawhip";
            i = i + 1;
            return (
                <tr key={id} style={{ fontSize: 14, backgroundColor: bgcolor, opacity: "75%" }}>
                    <td>{user}</td>
                    <td>{category}</td>
                    <td>{resource}</td>
                    <td>{resource_id}</td>
                    <td>{visibility}</td>
                    <td>{timestamp}</td>
                    <td>
                        <div >
                            <Button onClick={() => this.approve({ id }, { user }, { group_id }, { category }, { type }, { resource }, { resource_id }, { visibility }, { status }, { timestamp })} style={{ display: "inline-block", float: "left", backgroundColor: "green", color: "white", width: 100 }} > <FcApprove /> Approve</Button>
                            <Button onClick={() => this.reject({ id }, { user }, { group_id }, { category }, { type }, { resource }, { resource_id }, { visibility }, { status }, { timestamp })} style={{ display: "inline-block", float: "left", backgroundColor: "red", color: "white", width: 100 }} > <FcDisapprove />Reject</Button>
                        </div>
                    </td>
                </tr>
            )
        })
    }
    render() {
        return (
            <div>
                <Table style={{ marginTop: 20 }} hover id="requests" >
                    <tbody>
                        <tr style={{ fontSize: 18, backgroundColor: "peru" }}>
                            <th>Username</th>
                            <th>Type</th>
                            <th>Resource</th>
                            <th>Resource ID</th>
                            <th>Visibility</th>
                            <th>Timestamp</th>
                            <th>Status</th>
                        </tr>
                        {this.renderTableData()}
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default ApprovePendingGitlabRequest;