import React, { Component } from 'react'
import { Button, Form, Input, Table, } from 'reactstrap';
import Select from 'react-select'
import SecureStorage from 'secure-web-storage';

const opt = [
    // { value: 'prod', label: 'Production' },
    { value: 'stg', label: 'Staging' }
    // { value: 'agitha', label: 'Agitha' }
]

var today = new Date();
var tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000));
var day_after_tommorow = new Date(tomorrow.getTime() + (24 * 60 * 60 * 1000));

var tomorrow_dd = tomorrow.getDate();
var tomorrow_mm = tomorrow.getMonth() + 1;
var tomorrow_year = tomorrow.getFullYear();

if (tomorrow_mm !== 10 && tomorrow_mm !== 11 && tomorrow_mm !==12){
    tomorrow_mm = "0" + tomorrow_mm;
}


var day_after_tommorow_dd = day_after_tommorow.getDate();
var day_after_tommorow_mm = day_after_tommorow.getMonth() + 1;
var day_after_tommorow_year = day_after_tommorow.getFullYear();

if (day_after_tommorow_mm !== 10 && day_after_tommorow_mm !== 11 && day_after_tommorow_mm !==12){
    day_after_tommorow_mm = "0" + day_after_tommorow_mm;
}

tomorrow = tomorrow_year + "-" + tomorrow_mm + "-" + tomorrow_dd;
day_after_tommorow = day_after_tommorow_year + "-" + day_after_tommorow_mm + "-" + day_after_tommorow_dd;

const date_choices = [
    { value: tomorrow, label: tomorrow },
    { value: day_after_tommorow, label: day_after_tommorow }
]

const sql_choices = [
    // { value: 'prod', label: 'Production' },
    { value: 'classic-staging-20230710', label: 'classic-staging-20230710' },
    { value: 'dwh-staging', label: 'dwh-staging' },
    { value: 'hw-percona-staging-20190731', label: 'hw-percona-staging-20190731' },
    { value: 'psql-staging-20180706', label: 'psql-staging-20180706' },
    { value: 'staging-db-migration', label: 'staging-db-migration' }
    // { value: 'test_instance', label: 'test_Instance_name' }
    // { value: 'agitha', label: 'Agitha' }
]

export default class KubeUpscaleRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // email: '',
            namespace: '',
            env: '',
            envName: '',
            excludeTime: '',
            instance: '',
            instanceName: '', //name of the sql  instance
            invalid: true,
            // checkEmail: false,
            clusterReset: -1,
            instanceReset: -1, //sql instance reset
            timeReset: -1, //time reset
            showCluster: false,
            showInstance: false, // for sql instance
            showNamespace: false,
            // showEmail: false,
            // show_accessReason: false,
            show_excludeTime: false,
            secStorage: '',
            // accessReason: '',
        }
        // this.handleChangeEmail = this.handleChangeEmail.bind(this)
        this.handleChangeInstance = this.handleChangeInstance.bind(this) // for sql instance field
        this.handleChangeNamespace = this.handleChangeNamespace.bind(this)
        this.handleChangeEnv = this.handleChangeEnv.bind(this)
        this.handleChangeTime = this.handleChangeTime.bind(this)
    }

    componentDidMount() {
        console.log(today)
        console.log(tomorrow)
        console.log(day_after_tommorow)
        var CryptoJS = require("crypto-js");
        var SECRET_KEY = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY;
        var secureStorage = new SecureStorage(localStorage, {
            hash: function hash(key) {
                key = CryptoJS.SHA256(key, SECRET_KEY);
                return key.toString();
            },
            encrypt: function encrypt(data) {
                data = CryptoJS.AES.encrypt(data, SECRET_KEY);
                data = data.toString();
                return data;
            },
            decrypt: function decrypt(data) {
                data = CryptoJS.AES.decrypt(data, SECRET_KEY);
                data = data.toString(CryptoJS.enc.Utf8);
                return data;
            }
        });
        this.setState({ secStorage: secureStorage.getItem('UserInfo') })

    }

    // handleChangeEmail = (e) => {
    //     if (this.state.secStorage.role === 'user') {
    //         if (this.state.namespace.length > 0 & this.state.env.length > 0 & this.state.excludeTime.length > 0) this.setState({ invalid: false }); else { this.setState({ invalid: true }); this.setState({ showEmail: true }) }

    //     }
    //     else {
    //         this.setState({ showCluster: true })
    //         this.setState({ showNamespace: true })
    //         this.setState({ show_excludeTime: true })
    //         const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    //         var checkEmail = expression.test(String(e.target.value).toLowerCase())
    //         if (checkEmail === true) this.setState({ checkEmail: true }); else this.setState({ checkEmail: false })
    //         this.setState({ email: e.target.value })
    //         if (checkEmail === true & this.state.namespace.length > 0 & e.target.value.length > 0 & this.state.env.length > 0 & this.state.excludeTime.length > 0) this.setState({ invalid: false }); else { this.setState({ invalid: true }); this.setState({ showEmail: true }) }
    //     }
    // }

    // handleChangeNamespace = (e) => {
    //     if (this.state.secStorage.role === 'user') {
    //         this.setState({ showCluster: true })
    //         this.setState({ show_excludeTime: true })
    //         if (e.target.value.length > 0 & this.state.env.length > 0 & this.state.excludeTime.length > 0) this.setState({ invalid: false }); else { this.setState({ invalid: true }); this.setState({ showNamespace: true }) }
    //         this.setState({ namespace: e.target.value })
    //     }
    //     else {
    //         this.setState({ showEmail: true })
    //         this.setState({ showCluster: true })
    //         this.setState({ show_excludeTime: true })
    //         if (this.state.email.length > 0 & e.target.value.length > 0 & this.state.env.length > 0 & this.state.excludeTime.length > 0) this.setState({ invalid: false }); else { this.setState({ invalid: true }); this.setState({ showNamespace: true }) }
    //         this.setState({ namespace: e.target.value })
    //     }
    // }

    handleChangeNamespace = (e) => {
            this.setState({ showCluster: true })
            this.setState({ show_excludeTime: true })
            this.setState({ showInstance: true })
            if (e.target.value.length > 0 & this.state.env.length > 0 & this.state.excludeTime.length > 0 & this.state.instanceName.length > 0) this.setState({ invalid: false }); else { this.setState({ invalid: true }); this.setState({ showNamespace: true }) }
            this.setState({ namespace: e.target.value })
    }

    // handleChangeEnv = (e) => {
    //     if (this.state.secStorage.role === 'user') {
    //         if (e.value.length > 0 & this.state.namespace.length > 0 && this.state.excludeTime.length > 0) this.setState({ invalid: false }); else this.setState({ invalid: true });
    //         this.setState({ env: e.value })
    //         this.setState({ envName: e.label })
    //         this.setState({ clusterReset: e })
    //     }
    //     else {
    //         if (this.state.checkEmail === true & this.state.namespace.length > 0 & e.value.length > 0 & this.state.email.length > 0 & this.state.excludeTime.length > 0) this.setState({ invalid: false }); else this.setState({ invalid: true });
    //         this.setState({ env: e.value })
    //         this.setState({ envName: e.label })
    //         this.setState({ clusterReset: e })
    //     }
    // }

    handleChangeEnv = (e) => {
            if (e.value.length > 0 & this.state.namespace.length > 0 && this.state.excludeTime.length > 0 & this.state.instanceName.length > 0) this.setState({ invalid: false }); else this.setState({ invalid: true });
            this.setState({ env: e.value })
            this.setState({ envName: e.label })
            this.setState({ clusterReset: e })
        }
    
    handleChangeInstance = (e) => {
            // this.setState({ showCluster: true })
            // this.setState({ showNamespace: true })
            // this.setState({ show_excludeTime: true })
            if (e.value.length > 0 & this.state.env.length > 0 & this.state.namespace.length > 0 & this.state.excludeTime.length > 0) this.setState({ invalid: false }); else  this.setState({ invalid: true });
            this.setState({ instance: e.value })
            this.setState({ instanceName: e.label })
            this.setState({ instanceReset: e })
    }

    handleChangeTime= (e) => {
            // this.setState({ showCluster: true })
            // this.setState({ showNamespace: true })
            // this.setState({ showInstance: true })
            if (e.value.length > 0 & this.state.env.length > 0 & this.state.namespace.length > 0 & this.state.instanceName.length >0 ) this.setState({ invalid: false }); else { this.setState({ invalid: true }); this.setState({ show_excludeTime: true }) }
            this.setState({ excludeTime: e.label })
            this.setState({ timeReset: e })
    }

   
    

    submitHandler = (e) => {
        var userName = this.state.secStorage.email;
        var springCreds = process.env.REACT_APP_SPRING_CREDS;
        var base64 = btoa(springCreds);
        var baseUrl = process.env.REACT_APP_SPRING_ENDPOINT;
        var today = new Date()
        var axios = require('axios');
        var FormData = require('form-data');
        var data = new FormData();
        let mon = today.getMonth() + 1
        data.append('username', userName);
        data.append('description', this.state.envName);
        data.append('namespace', this.state.namespace);
        data.append('exclude_Time', this.state.excludeTime);
        data.append('sql_instance', this.state.instanceName);
        // data.append('accessReason', this.state.accessReason)
        // data.append('acc_level', 'Full Access');
        data.append('approvedBy', 'Pending');
        data.append('status', 'Pending');
        data.append('timestamp', today.getDate() + "-" + mon + "-" + today.getFullYear() + ":" + today.getHours() + ":" + today.getMinutes());
        var config = {
            method: 'post',
            url: baseUrl + '/k8suprequest',
            headers: {
                'Authorization': 'Basic ' + base64,
                'content-type': 'multipart/form-data'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
            })
            .catch(function (error) {
                alert(error)
                console.log(error);
            });
        this.setState({ clusterReset: -1 })
        this.setState({ instanceReset: -1 })
        this.setState({ timeReset: -1})
        document.getElementById("form2").reset();
        this.setState({ invalid: true });
        this.setState({ showCluster: false });
        this.setState({ showInstance: false });
        this.setState({ showNamespace: false });
        this.setState({ show_excludeTime: false });
        // this.setState({ showEmail: false });
        // this.setState({ checkEmail: false });
        this.setState({ env: '' });
        this.setState({ envName: '' });
        // this.setState({ email: '' });
        this.setState({ namespace: '' });
        this.setState({ excludeTime: '' });
        this.setState({ instance: '' });
        this.setState({ instanceName: '' });
        // this.setState({ accessReason: '' });
    }

    render() {
        var emailInput;
        emailInput = <Input defaultValue={this.state.secStorage.email} readOnly style={{ fontSize: 16 }} type="email" />;

        return (
            <div style={{ marginLeft: 20 }}>
                <Form id="form2">
                    <Table style={{ fontSize: 16, width: "30%", marginLeft: 400, marginTop: "18%", }}>
                        <tbody >
                            <tr>
                                <td>{emailInput}
                                    {/* {this.state.email.length <= 0 && this.state.showEmail &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>Email cannot be empty</span>} */}

                                    {/* {this.state.checkEmail === false && this.state.showEmail &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>&nbsp;&nbsp;Invalid Email</span>} */}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Select value={this.state.clusterReset} placeholder="Select Cluster" id="category" options={opt} onChange={(e) => this.handleChangeEnv(e)} />
                                    {this.state.envName.length <= 0 && this.state.showCluster &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>Select Cluster</span>}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Input style={{ fontSize: 16 }} type="text" placeholder="Enter namespaces comma separated eg: ns1, ns2, ..." onChange={(e) => this.handleChangeNamespace(e)} />
                                    {this.state.namespace.length <= 0 && this.state.showNamespace &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>Namespace cannot be empty</span>}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Select value={this.state.instanceReset} placeholder="Select SQL Instance" id="category" options={sql_choices} onChange={(e) => this.handleChangeInstance(e)} />
                                    {this.state.instanceName.length <= 0 && this.state.showInstance &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>Select SQL instance</span>}
                                </td>
                            </tr>
                            
                            {/* date input in drop down menu */}
                            <tr>
                                <td>
                                    <Select value={this.state.timeReset} placeholder="Select exclusion date" id="category" options={date_choices} onChange={(e) => this.handleChangeTime(e)} />
                                    {this.state.excludeTime.length <= 0 && this.state.show_excludeTime &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>Exclusion date cannot be empty</span>}
                                </td>
                            </tr>
                            {/* <tr>
                                <td>
                                    <Input style={{ fontSize: 16 }} type="text" placeholder="Enter date in format yyyy-mm-dd" onChange={(e) => this.handleChangeTime(e)} />
                                    {this.state.excludeTime.length <= 0 && this.state.show_excludeTime &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>Exclusion date cannot be empty</span>}
                                    {this.state.excludeTime.length >= 100 && this.state.show_excludeTime &&
                                        <span style={{ fontSize: 9, color: "red", float: "left" }} className='error'>Exclusion date has to be in format yyyy-mm-dd</span>}    
                                </td>
                            </tr> */}
                            
                           
                            <tr>
                                <td><Button disabled={this.state.invalid} style={{ fontSize: 16, marginLeft: 130, backgroundColor: "#33382e", color: "white" }} onClick={this.submitHandler} >Submit</Button></td>
                            </tr>
                        </tbody>
                    </Table>

                </Form>
            </div>
        )
    }
}
